import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Colors } from "../constants/Colors"
import { SkRoutes } from "../constants/Routes"
import { ArtistSearchResult } from "../data/models/Artist"
import { ArtistSearchParams } from "../data/requests/ArtistSearchParams"
import { ArtistIsType, useArtistIs } from "../hooks/useArtistIs"
import { ArtistOnLineCircleButtonManaged } from "../screens/ArtistScreen/ArtistOnListButton"
import { ArtistPlanet } from "./ArtistPlanet"
import { SkCard } from "./Card"
import { Flex } from "./Flex"
import { SkText } from "./SkText"

import "./ShortArtistCell.scss";

export const ShortArtistCell = React.memo((props: {
    artist: ArtistSearchResult
    button?: ArtistIsType
    onClick?: () => void
    highlight?: boolean
    searchContext?: ArtistSearchParams
}) => {

    const { artist, button, onClick, searchContext, highlight } = props
    const muted = useArtistIs('muted', artist.handle)
    const nav = useNavigate()

    const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        onClick?.();
        nav(SkRoutes.Artist.Show(artist.handle, searchContext));
        e.preventDefault();
    }, [ onClick, artist, searchContext, nav ])

    if (!artist) return null;

    return (
        <a href={SkRoutes.Artist.Show(artist.handle)} onClick={handleClick} className={`artist-tile` + (highlight ? ' selected' : '')}>
            <ArtistPlanet artist={ artist } size={ 32 } variant="small" />
            <Flex style={{ flex: 1 }}>
                {/* <SkText variant='c2'>{ artist.name }</SkText> */}
                <div className="artist-name">{ artist.name }</div>
                <Flex row>
                    <SkText variant='c2' faded>{ artist.handle }</SkText>

                    { muted.onList && <span className="subtle" style={{ color: Colors.FailureRed, marginLeft: '10px' }}>Muted</span> }
                    {/* { muted.onList && <SkText variant='c2' style={{ color: Colors.FailureRed }}>Muted</SkText> } */}

                    {/* { muted.onList
                        ? <SkText variant='c2' style={{ marginLeft: 20, color: Colors.FailureRed }}>Muted</SkText>
                        : <SkText variant='c2' style={{ marginLeft: 20 }} faded>{ artist.tags?.[0] }</SkText> } */}
                </Flex>
            </Flex>
            {
                button ? <ArtistOnLineCircleButtonManaged artistHandle={artist.handle} list={ button } /> : null
            }
        </a>
    );

    // return (
    //     <SkCard
    //         className={`cell search-result-artist` + (highlight ? ' selected' : '')}
    //         // style={{ backgroundColor: highlight ? Colors.DarkBackground : Colors.DarkerBackground, padding: '10px' }}
    //         onClick={ handleClick }
    //     >
    //         <Flex row align='center'>
    //             <ArtistPlanet artist={ artist } size={ 35 } />
    //             <Flex style={{ flex: 1 }}>
    //                 <SkText variant='c2' style={{ marginLeft: 20 }}>{ artist.name }</SkText>
    //                 <Flex row>
    //                     <SkText variant='c2' style={{ marginLeft: 20 }} faded>{ artist.handle }</SkText>

    //                     { muted.onList && <SkText variant='c2' style={{ marginLeft: 20, color: Colors.FailureRed }}>Muted</SkText> }

    //                     {/* { muted.onList
    //                         ? <SkText variant='c2' style={{ marginLeft: 20, color: Colors.FailureRed }}>Muted</SkText>
    //                         : <SkText variant='c2' style={{ marginLeft: 20 }} faded>{ artist.tags?.[0] }</SkText> } */}
    //                 </Flex>
    //             </Flex>
    //             {
    //                 button ? <ArtistOnLineCircleButtonManaged artist={ artist } list={ button } /> : null
    //             }
    //         </Flex>
    //     </SkCard>
    // )

})
