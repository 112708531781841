import { create } from "zustand"
import { AlgoliaRecord } from "../../data/models/AlgoliaRecord"
import { HitType } from "./SearchHitContainer"

export type SearchUiDirective = { type: HitType, object?: AlgoliaRecord }
type SearchUiContext = {

    // Algolia keeps track of this query in their hooks, but their hooks are broken :)
    // So instead we'll do it here
    query: string
    setQuery: (q: string) => void

    resultsVisible: boolean
    showResults: () => void
    hideResults: () => void
    setResultVisibility: (visible: boolean) => void

    selectedIndex: number
    selectedDirective: SearchUiDirective
    setSelectedIndex: (idx: number) => void
    setSelectedDirective: (dir: SearchUiDirective) => void

    nResults: number
    setNResults: (n: number) => void
}

export const useHeaderSearchUiContext = create<SearchUiContext>((set, get) => {

    return {

        query: '',
        setQuery: (q: string) => set({ query: q }),

        resultsVisible: false,
        showResults: () => set({ resultsVisible: true }),
        hideResults: () => set({ resultsVisible: false }),
        setResultVisibility: resultsVisible => set({ resultsVisible }),

        selectedIndex: 0,
        selectedDirective: { type: 'search' },
        setSelectedIndex: (idx: number) => {
            let nextVal = idx
            const n = get().nResults
            const [ min, max ] = [ -1, n - 1 ]
            if (idx < min) nextVal = max
            if (idx > max) nextVal = min
            set({ selectedIndex: nextVal })
        },
        setSelectedDirective: (dir: SearchUiDirective) => set({ selectedDirective: dir }),

        nResults: 0,
        setNResults: (n: number) => set({ nResults: n })
    }

})