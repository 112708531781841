const runningLocally = /(localhost|\d{3}\..*)/.test(window.location.host)
const webDomain = window.location.origin;
const apiDomain = runningLocally ? 
    `${window.location.protocol}//${window.location.hostname}:42855` : 
    `${window.location.protocol}//api.${window.location.host}`;

export const getSkApiDomain = () => apiDomain
export const getSkWebAppDomain = () => webDomain

export const MakeUrl = (path: string, query?: object) => {
    const queryString = Object.entries(query || {}).map(([ key, value ]) => `${key}=${encodeURIComponent(value)}`).join('&')
    const url = `${getSkApiDomain()}/${path.replace(/^\//, '')}${queryString ? `?${queryString}` : ''}`
    return url;
}

export const TwitterUrl = {
    ForUser: (handle: string) => `https://www.twitter.com/${ handle }`,
    ForTweet: (authorHandle: string, tweetId: string) => `https://www.twitter.com/${ authorHandle }/status/${tweetId}`,
}

export const BskyUrl = {
    ForUser: (handle: string) => `https://bsky.app/profile/${handle}`,
    ForPost: (handle: string, postId: string) => `https://bsky.app/profile/${handle}/post/${postId}`
}
