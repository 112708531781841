import React, { useEffect, useMemo, useState } from "react";
import { SetWebPushSubscriptionAction } from "../../data/actions/NotificationActions";
import { Notifications } from "../../utils/Notifications";
import { Bump } from "../Bump";
import { Flex } from "../Flex";
import { SkButton } from "../SkButton";
import { SkText } from "../SkText";
import { NotificationSetupSuccessPage } from "./NotificationSetupSuccessPage";

type Status = 'ready' | 'setting' | 'set' | 'error'
export const WebPushSetupPage = React.memo((props: { onDone: () => void }) => {

    const { onDone } = props
    const [ status, setStatus ] = useState<Status>('ready')
    const meta = Notifications.useNotificationMethodStatus('webpush')

    const promptWebPush = async () => {
        try {
            const permResult = await Notification.requestPermission()
            if (permResult !== 'granted') return;
            const result = await Notifications.WebPush.subscribe()
            setStatus('setting')
            if(result)
                await SetWebPushSubscriptionAction(result.toJSON())
            setStatus('set')
        } catch (e) {
            console.log(e)
            setStatus('error')
        }
    }

    // useEffect(() => {
    //     switch (meta.status) {
    //         case 'active': setStatus('set'); break;
    //         case 'not-configured': setStatus('ready'); break;
    //         case 'paused':
    //         case 'different-device': onDone(); break;
    //     }
    // }, [ meta.status ])

    const text = useMemo(() => {
        switch (status) {
            case 'error': return 'Sorry, it looks like there was a problem setting up your push notifications';
            case 'ready': return 'Press the button below and then say yes when asked if you would like notifications'
            case 'setting': return 'Configuring...'
            case 'set': return 'Done!'
        }
    }, [ status ])

    if (status === 'set') return (
        <NotificationSetupSuccessPage method='webpush' onDone={ onDone } />
    )

    return (
        <Flex style={{ flex: 1, width: '100%' }}>

            <Flex justify='center' align='center' style={{ flex: 1, width: '100%' }}>
                { meta.icon }
                <Bump h={ 40 } />
                <SkText variant='c1' style={{ textAlign: 'center' }}>{ text }</SkText>
                <Bump h={ 20 } />
                { status === 'error' ? <SkText variant='c2' faded style={{  textAlign: 'center' }}>Older browsers may not support the notification interface Skrunkle uses. Check that your browser is fully up-to-date and try again.</SkText> : null }
            </Flex>

            { status === 'ready' ? <SkButton title='Send Prompt' disabled={ status !== 'ready' } onClick={ promptWebPush } /> : null }

        </Flex>
    )

})
