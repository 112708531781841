import { useMemo } from "react"
import { ArtistProfile } from "../data/models/Artist"

export const useSortedArtists = (artists: ArtistProfile[]): ArtistProfile[] => {
    return useMemo(() => {

        const open = artists.filter(a => a.commissionStatus === 'open')
        const soon = artists.filter(a => a.commissionStatus === 'opening-soon')
        const rest = artists.filter(a => a.commissionStatus !== 'opening-soon' && a.commissionStatus !== 'open')
        const soonestFirst = (artists: ArtistProfile[]) => [ ...artists ].sort((a, b) => {
            if(a.commissionStatusDate && b.commissionStatusDate) {
                return a.commissionStatusDate > b.commissionStatusDate ? -1 : 1
            }
            else if(a.commissionStatusDate) {
                return -1;
            }
            else if(b.commissionStatusDate) {
                return 1;
            } else {
                return 0;
            }
        })
        return soonestFirst(open).concat(soonestFirst(soon)).concat(soonestFirst(rest))

    }, [ artists ])
}