import React from "react";
import { ArtistPlanet } from "../../components/ArtistPlanet";
import { Flex } from "../../components/Flex";
import { SkText } from "../../components/SkText";
import { TagCloud } from "../../components/TagCloud";
import { ArtistProfile } from "../../data/models/Artist";
import { useWindowSize } from "../../hooks/useWindowSize";

export const ArtistHeader = (props: { artist: ArtistProfile }) => {

    const { artist } = props
    const { isMobile } = useWindowSize();

    return (
        <div>
            <Flex row align='stretch'>
                <Flex row justify='center'>
                    <ArtistPlanet artist={ artist } size={ isMobile ? 100 : 100 } />
                </Flex>
                <Flex justify='space-between' style={{ marginLeft: 20, flex: 1}}>
                    <Flex style={{ marginBottom: 10 }}>
                        <SkText variant='c1'>{ artist?.name }</SkText>
                        <SkText variant='c1' faded>{ artist?.handle }</SkText>
                        <SkText variant='c1' faded>{ artist?.pronouns }</SkText>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    )

}
