import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { CgShare } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { Bump } from "../components/Bump";
import { FeedItemDisplay } from "../components/Feed";
import { Flex } from "../components/Flex";
import { Logo } from "../components/Logo";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { SkButton, SkButtonVariant, SkCircleButton } from "../components/SkButton";
import { SkImageTheater } from "../components/SkImageTheater";
import { Tags } from "../constants/RelevantTags";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { GetPostAction } from "../data/actions/FeedActions";
import { QueryKeys } from "../data/QueryKeys";
import { useAuth } from "../hooks/useAuth";
import { useSelectedFeedItem } from "../hooks/useSelectedFeedItem";
import { useWindowSize } from "../hooks/useWindowSize";
import { Share } from "../utils/Share";
import { ArtistFollowButton } from "./ArtistScreen/ArtistFollowButton";
import { ArtistSubscribeButton } from "./ArtistScreen/ArtistSubscribeButton";

export const PostScreen = React.memo(() => {

    const { postId, artistId } = useParams<{ postId: string, artistId: string }>();
    const q = useQuery(QueryKeys.Posts.Get(postId || ''), () => GetPostAction(postId || ''), { enabled: !!postId });
    const { isMobile } = useWindowSize()
    const theater = useSelectedFeedItem()
    const post = useMemo(() => q?.data?.data, [ q ])
    const nav = useNavigate()

    const auth = useAuth();
    const anon = !auth.user
    const buttons: { title: string, onClick: () => void, variant: SkButtonVariant }[] = useMemo(() => {
        if (!post)
            return [];

        const topLevelTag = (post.tags || []).find(t => Tags.TopTagSet.has(t)) || ''
        const artistType = {
            'Fursuits': 'Fursuit Makers',
            'Fursuit Accessories': 'Fursuit Gear Creators',
            'Accessories': 'Furry Makers',
            '3D': '3D Anthro Artists',
            'Digital Art': 'Furry Artists',
        }[topLevelTag] || 'Furry Artists and Creators'

        if (anon) {
            return [
                { title: 'Set Telegram Reminder', onClick: () => nav(SkRoutes.Profile()), variant: 'primary' },
                { title: `Discover More ${ artistType }`, onClick: () => nav(SkRoutes.Browse({ tags: [topLevelTag] })), variant: 'secondary-invert' },
            ]
        } else {
            return []
        }

    }, [ anon, post, nav ])

    if (!post || !artistId) 
        return null;

    console.log({ post })

    return (
        <ScreenWrapper skipAuth contentWidth={ UiConstants.feedItemWidth }>
            <Flex align='stretch' justify={ isMobile ? 'flex-start' : 'center' } style={{ height: '100%' }}>
                { anon ? <Flex row justify='center' style={{ marginBottom: 40 }}><Logo /></Flex> : null }
                <FeedItemDisplay item={ post } onImageClick={ idx => theater.open(post, idx) } />
                <Flex align='stretch' style={{ margin: '0 40px' }}>
                    {
                        anon ? null : (
                            <Flex row>
                                <ArtistFollowButton artistHandle={ artistId } />
                                <Bump w={ UiConstants.tightSpacing } />
                                <ArtistSubscribeButton artistHandle={ artistId } />
                                <Bump w={ UiConstants.tightSpacing } />
                                <SkCircleButton onClick={ () => Share.Post(post) }>
                                    <CgShare color='white' size={ UiConstants.iconButtonSize } />
                                </SkCircleButton>
                            </Flex>
                        )
                    }
                    <Bump h={ UiConstants.standardSpacing } />
                    {
                        buttons.map(b => (
                            <SkButton variant={ b.variant } onClick={ b.onClick } title={ b.title } style={{ marginBottom: UiConstants.standardSpacing }} />
                        ))
                    }
                </Flex>
            </Flex>

            { post.media?.length &&
                <SkImageTheater images={ post.media } toggle={ theater.toggle } start={ theater.context.selectedIndex } />
            }
            
        </ScreenWrapper>
    )

})