import React, { useMemo } from "react";
import { Configure } from "react-instantsearch-core";

// Creating this component because of Algolia's ridiculously undocumented way of doing this x.x
export const ConfigureSearchForTypes = React.memo((props: {
    types: string[]
}) => {

    const { types } = props

    // e.g., [["type:artist","type:tag"]]
    const filterString = useMemo(() => `[[${types.map(t => `"${t}"`).join(',')}]]`, [types])

    return <Configure {...{ facets: [ '["*"]', 'type' ], facetFilters: filterString } as any} />

    // return (
    //     <Configure
    //         facets={[ '["*"]', 'type' ]}
    //         facetFilters={ filterString }
    //     />
    // )
})

export type FacetConfigSearchParams = Partial<{
    tags: string[],
    commissionStatuses: string[],
    query: string,
    types: string[],
}>

export type AlgoliaFacetConfig = {
    facetFilters: string[],
    facets: string[]
}

//export const useAlgoliaFilter = (params: FacetConfigSearchParams, deps?: any[]): string => useMemo(() => CreateAlgoliaFilter(params), deps)

// e.g., type:artist OR type:post
// e.g., (type:artist OR type:post) AND (commissionStatus:open) AND (tags:Digital Art)
export const CreateAlgoliaFilter = (params: FacetConfigSearchParams) => {

    const orList = (facetName: string, list?: string[]): string | null => {
        if (!list?.length) 
            return null;
        else
            return `(${ list.map(t => `${facetName}:'${t}'`).join(' OR ') })`
    }

    const andOperands = [
        orList('type', params.types),
        orList('commissionStatus', params.commissionStatuses?.filter(s => s !== 'all')),
        orList('tags', params.tags)
    ].filter(x => x !== null)

    const filter = !!andOperands?.length ? andOperands.join(' AND ') : undefined
    return filter
}
