import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Bump } from "../../components/Bump";
import { SkCard } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { SkButton } from "../../components/SkButton";
import { SkModal } from "../../components/SkModal";
import { SkText } from "../../components/SkText";
import { SkTextInput } from "../../components/SkTextInput";
import { Colors, CommissionGradient } from "../../constants/Colors";
import { UiConstants } from "../../constants/UiConstants";
import { SendArtistStatusReportAction } from "../../data/actions/ReportActions";
import { ArtistProfile, CommissionStatus } from "../../data/models/Artist";
import { useAuth } from "../../hooks/useAuth";
import { ToggleModel } from "../../hooks/useToggle";
import { ReportFailedPage, ReportSubmittedPage, SubmittingReportPage } from "./ReportProgressPages";

const statusOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Opening Soon', value: 'opening-soon' },
    { label: 'Closed', value: 'closed' },
]

type Page = 'form' |'success' | 'failure'

export const ArtistStatusCorrectionModal = React.memo((props: { artist: ArtistProfile, toggle: ToggleModel }) => {

    const { artist, toggle } = props
    const { user } = useAuth()
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ page, setPage ] = useState<Page>('form')

    useEffect(() => {
        if ((typeof user?.reportCredits === 'number') && (user?.reportCredits <= 0) && page === 'form') {
            setPage('failure')
            setErrorMessage('Sorry, you have run out of report credits. You will be able to submit more reports once your existing reports have been reviewed.')
        }
    }, [ user, page ])

    const handleClose = () => {
        setPage('form')
        toggle.close()
    }

    const content = useMemo(() => {
        switch (page) {
            case 'failure': return <ReportFailedPage message={ errorMessage } onDismiss={ handleClose } />
            case 'success': return <ReportSubmittedPage onDismiss={ handleClose } />
            case 'form': default: return <StatusCorrectionForm artist={ artist } toggle={ toggle } onSuccess={ () => setPage('success') } onFailure={ (message) => { setErrorMessage(message); setPage('failure'); } } />
        }
    }, [ page, errorMessage, artist, toggle ])

    return (
        <SkModal title='Artist Status Report' open={ toggle.isOpen } onClose={ handleClose } closerStyle='x' noPadding>
            { content }
        </SkModal>
    )

})

const StatusCorrectionForm = React.memo((props: {
    artist: ArtistProfile,
    toggle: ToggleModel,
    onSuccess: () => void,
    onFailure: (message: string) => void
}) => {

    const { artist, toggle, onSuccess, onFailure } = props
    const [ correctedStatus, setCorrectedStatus ] = useState<CommissionStatus>('unknown')
    const [ tweetUrl, setTweetUrl ] = useState<string>('')
    const [ submitting, setSubmitting ] = useState(false)

    //const artistPattern = useMemo(() => new RegExp(`https:\\/\\/(www\\.)?twitter.com\\/${ artist?.twitter }\\/status\\/\\d+`), [ artist ])
    const canSubmit = useMemo(() => { return !!correctedStatus }, [ correctedStatus ])

    const handleSubmit = async () => {
        try {
            setSubmitting(true)
            const response = await SendArtistStatusReportAction({ correctedStatus, artistHandle: artist?.handle, tweetUrl })
            onSuccess()
            setSubmitting(false)
        } catch (e) {
            const ae = e as AxiosError
            const message = (ae.response?.data as any)?.message
            onFailure(message)
        }
        setSubmitting(false)
    }

    if (submitting) return <SubmittingReportPage />

    return (
        <Flex align='stretch'>
            <div style={{ margin: '0 20px' }}>
                <SkText variant='c1'>What should their status have been?</SkText>
                <Bump h={ UiConstants.standardSpacing } />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', maxWidth: '100%' }}>
                <Bump w={ UiConstants.screenInset } />
                {
                    statusOptions.filter(s => s.value !== artist?.commissionStatus).map(o => (
                        <SkCard style={{
                            height: 120, minWidth: 180, maxWidth: 180, marginRight: UiConstants.tightSpacing,
                            background: o.value === correctedStatus ? CommissionGradient(correctedStatus) : Colors.InactiveGray,
                            opacity: correctedStatus ? (correctedStatus === o.value ? 1 : 0.1) : 1
                        }} onClick={ () => setCorrectedStatus(o.value as any) }>
                            <Flex justify='center' align='center' style={{ width: '100%', height: '100%' }}>
                                <SkText variant='section' style={{ textAlign: 'center' }}>{ o.label }</SkText>
                            </Flex>
                        </SkCard>
                    ))
                }
                <Bump w={ 50 } />
            </div>

            <Flex style={{ margin: '0 20px' }}>
                <Bump h={ UiConstants.standardSpacing * 2 } />
                <SkText variant='c1'>Status suggestions require a tweet as evidence</SkText>
                <Bump h={ 5 } />
                <SkText variant='c2' faded>(Optional) Paste a link to the tweet that supports this suggestion below</SkText>
                <Bump h={ UiConstants.standardSpacing } />
                <SkTextInput noAutocomplete noAutocorrect noCapitalize value={ tweetUrl } onTextChange={ setTweetUrl }
                    placeholder={`https://twitter.com/${ artist?.twitter }/status/...`} />
            </Flex>

            <Bump h={ UiConstants.standardSpacing * 3 } />
            <Flex style={{ margin: '0 20px' }}>
                <SkButton disabled={ !canSubmit || submitting } title={ submitting ? 'Submitting...' : 'Submit Report' } onClick={ handleSubmit } style={{ opacity: canSubmit ? 1 : 0.3 }} />
                <Bump h={ UiConstants.tightSpacing } />
                <SkButton title='Nevermind' variant='secondary-invert' onClick={ toggle.close } />
            </Flex>
        </Flex>
    )

})