type PopStateHandler = (ev?: PopStateEvent) => void
let popStateHandlers: (PopStateHandler)[] = []

export const WindowEvents = {
    popState: {
        subscribe: (handler: PopStateHandler): number => {
            const idx = popStateHandlers.push(handler)
            window.onpopstate = (ev) => popStateHandlers.forEach(f => f(ev))
            return idx
        },
        unsubscribe: (subscription: number) => {
            popStateHandlers = popStateHandlers.splice(subscription, 1)
            window.onpopstate = (ev) => popStateHandlers.forEach(f => f(ev))
        }
    }
}