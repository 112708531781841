import { SkRoutes } from "../constants/Routes"
import { ArtistProfile } from "../data/models/Artist"
import { FeedItem } from "../data/models/FeedItem"

export type ShareOutcome = 'shared' | 'copied' | 'error'

const share = (data: ShareData): ShareOutcome => {
    try {
        navigator.share(data)
        return 'shared'
    } catch (e) {
        try {
            navigator.clipboard.writeText(window.location.href)
            return 'copied'
        } catch (ee) {
            alert('Sorry, your platform doesn\'t support sharing.');
            return 'error'
        }
    }
}

const elevatorPitch = (artistName?: string) => `Get notified when ${ artistName ? `${ artistName } and all of your other` : 'your' } favorite furry artists open for commission on Skrunkle`

const makeShareableLink = (route: string) => `${window.location.origin}/${route.replace(/^\//, '')}`

export const Share = {

    Artist: (artist: ArtistProfile) => {
        const artistName = `${ artist?.name || 'artist' } (@${ artist.handle }) on Skunkle`;
        return share({
            title: artistName, text: elevatorPitch(artist.name), url: makeShareableLink(SkRoutes.Artist.Show(artist.handle))
        })
    },

    Post: (post: FeedItem) => {

        const artistName = `${ post.artist?.name || '(Missing artist)' } (${ post.artistHandle })`;
        const title = {
            'finished-work': `Artwork by ${artistName}`,
            'commission-status-update': `Commission Status Update for ${artistName}`
        }[post.type]

        return share({ title, text: post.message, url: makeShareableLink(SkRoutes.Feed.Post(post.artistHandle, post.id)) })
    },

    ThisPage: () => share({ title: document.title, text: elevatorPitch(), url: window.location.href })
}