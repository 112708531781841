import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ScreenWrapper } from "../../components/ScreenWrapper";
import { SkRoutes } from "../../constants/Routes";
import { ArtistSearchResult } from "../../data/models/Artist";
import { FeedItem } from "../../data/models/FeedItem";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useWindowSize } from "../../hooks/useWindowSize";
import { QueryString } from "../../utils/QueryString";
import { NewBrowseScreenDesktop } from "./NewBrowseScreenDesktop";
import { NewBrowseScreenMobile } from "./NewBrowseScreenMobile";
import { useBrowseScreenSearchContext } from "./useBrowseScreenSearchContext";

export type SearchPayload = { artists: ArtistSearchResult[], posts: FeedItem[] }

export type SearchParams = Partial<{
    tags: string[],
    status: string[],
    q: string
}>;

export const useSearchParams = (): [SearchParams, (update: SearchParams) => void] => {

    const praw = QueryString.useParams<{ tags: string, status: string, view: 'feed' | 'all-artists', q: string }>()

    const p: SearchParams = useMemo(() => {

        return {
            tags: praw.tags?.split(',').map(s => s.trim()) || [],
            status: praw.status?.split(',').map(s => s.trim()) || ['all'],
            q: praw.q || ''
        }

    }, [praw])

    const nav = useNavigate()
    const set = useCallback((params: SearchParams) => nav(SkRoutes.Browse({ ...p, ...params })), [p])

    return [p, set]

}

export const BrowseStatusOptions = [
    { label: 'All Statuses', value: 'all' },
    { label: 'Open', value: 'open' },
    { label: 'Opening Soon', value: 'opening-soon' }
]

export const NewBrowseScreen = React.memo(() => {

    const { isMobile } = useWindowSize()
    const [params] = useSearchParams()
    const { searchUsingApi, searchUsingAlgolia, setResultsUndefined } = useBrowseScreenSearchContext()
    useAsyncEffect(async () => {
        setResultsUndefined()
        if (params.q) 
            searchUsingAlgolia(params.status || [], params.tags || [], params.q)
        else 
            searchUsingApi(params.status || [], params.tags || [])

    }, [ params ])

    return (
        <ScreenWrapper>
            {isMobile ? <NewBrowseScreenMobile /> : <NewBrowseScreenDesktop />}
        </ScreenWrapper>
    )
})
