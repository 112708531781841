import React from "react"
import { Colors } from "../constants/Colors";
import { UiConstants } from "../constants/UiConstants";

export const LoadingBox = React.memo((props: {
    width?: number,
    height?: number
}) => {

    const { width, height } = props

    return (
        <div style={{
            width: width || '100%',
            height: height || 200,
            backgroundColor: Colors.InactiveGray,
            borderRadius: UiConstants.borderRadius
        }}>
        </div>
    )

})