import { QueryClient } from "@tanstack/react-query";

let queryClient: QueryClient;

const GetQueryClient = () => {
    if (!queryClient) queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
                // staleTime: 1000 * 30, <-- this was causing things not to load sometimes...
            }
        }
    });
    return queryClient;
}

export const ReactQuery = {
    getClient: GetQueryClient
}