import Axios from 'axios'
import { CurrentUser } from '../models/CurrentUser'
import { SkResponse } from '../models/SkResponse'
import { UpdateUserRequest } from '../requests/UpdateUserRequest'
import { MakeUrl } from './Urls'

export const GetCurrentUserAction = async (): Promise<SkResponse<CurrentUser>> => await Axios.get(MakeUrl('/users/me'), { withCredentials: true })

export const UpdateCurrentUserAction = async (update: UpdateUserRequest): Promise<SkResponse<void>> => await Axios.put(MakeUrl('/users/me'), update)

export const SetUserProgress = async (flag: string): Promise<SkResponse<void>> => await Axios.put(MakeUrl(`/users/me/progress/${flag}`))
export const UnsetUserProgress = async (flag: string): Promise<SkResponse<void>> => await Axios.delete(MakeUrl(`/users/me/progress/${flag}`))
