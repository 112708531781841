import React, { useMemo } from "react"
import { UiConstants } from "../constants/UiConstants"
import { Flex } from "./Flex"

const spacing = 0

export interface ImageGridProps {
    imageUrls: string[]
    height: number
    onImageClick?: (index: number) => void
}

export const ImageGrid = React.memo((props: ImageGridProps) => {

    const { height, onImageClick } = props
    const imageUrlsRaw = props.imageUrls
    const imageUrls = imageUrlsRaw?.filter(u => !!u) || []
    const c = imageUrls?.length
    const handler = (index: number) => (() => onImageClick?.(index))

    if (!c) return null

    if (c === 1) {
        return (
            <div>
                <GridImage url={ imageUrls[0] } height={ height } onClick={ handler(0) } />
            </div>
        )
    }

    if (c === 2) {
        return (
            <Flex row style={{ width: '100%', height }}>
                <GridImage url={ imageUrls[0] } height={ height } bumper='right' onClick={ handler(0) } />
                <GridImage url={ imageUrls[1] } height={ height } bumper='left' onClick={ handler(1) } />
            </Flex>
        )
    }

    if (c === 3) {
        return (
            <Flex row style={{ width: '100%', height }}>
                <GridImage url={ imageUrls[0] } height={ height } bumper='right' onClick={ handler(0) } style={{ width: '50%' }} />
                <Flex style={{ flex: 1, marginLeft: spacing }}>
                    <GridImage url={ imageUrls[1] } height={ height / 2 } bumper='bottom' onClick={ handler(1) } />
                    <GridImage url={ imageUrls[2] } height={ height / 2 } bumper='top' onClick={ handler(2) }/>
                </Flex>
            </Flex>
        )
    }

    if (c === 4) {
        return (
            <Flex style={{ height, width: '100%' }}>
                <Flex row style={{ width: '100%', flex: 1, marginBottom: spacing }}>
                    <GridImage url={ imageUrls[0] } height={ height / 2 } bumper='right' onClick={ handler(0) }/>
                    <GridImage url={ imageUrls[1] } height={ height / 2 } bumper='left' onClick={ handler(1) }/>
                </Flex>
                <Flex row style={{ width: '100%', flex: 1, marginTop: spacing }}>
                    <GridImage url={ imageUrls[2] } height={ height / 2 } bumper='right' onClick={ handler(2) }/>
                    <GridImage url={ imageUrls[3] } height={ height / 2 } bumper='left' onClick={ handler(3) }/>
                </Flex>
            </Flex>
        )
    }

    return null;
})

const GridImage = React.memo((props: { url: string, height: number, bumper?: 'left' | 'right' | 'bottom' | 'top', onClick?: () => void, style?: React.CSSProperties }) => {

    const { url, height, bumper, onClick, style } = props

    const bumpStyles = useMemo(() => {
        switch (bumper)  {
            case 'left': return { marginLeft: spacing }
            case 'right': return { marginRight: spacing }
            case 'bottom': return { marginBottom: spacing }
            case 'top': return { marginTop: spacing }
            default: return {}
        }
    }, [ bumper ])

    return (
        <div style={{
            backgroundImage: `url(${ url })`, backgroundSize: 'cover', backgroundPosition: 'center',
            width: '100%', height, cursor: 'pointer', ...bumpStyles, ...style
            }}
            onClick={ onClick }>
        </div>
    )

})

const imageStyle = {
    borderRadius: UiConstants.borderRadius
}