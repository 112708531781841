import React, { MouseEventHandler } from "react";
import { Colors } from "../constants/Colors";
import { UiConstants } from "../constants/UiConstants";

import "./Card.scss";

interface SkCardProps {
    className?: string
    style?: React.CSSProperties
    onClick?: MouseEventHandler<HTMLDivElement>
    children: any
}

export const SkCard = React.memo((props: SkCardProps) => {

    const { style, onClick, children, className } = props;

    const classes = [
        'component-card',
        (className || ''),
        (onClick ? 'button' : '')
    ]

    return (
        <div className={classes.join(' ')} onClick={ onClick } style={{
            // borderRadius: UiConstants.borderRadius,
            // backgroundColor: Colors.DarkerBackground,
            // boxSizing: 'border-box',
            // maxWidth: '100%', padding: 20, cursor: onClick ? 'pointer' : undefined,
            ...style
        }}>
            { children }
        </div>
    )

})