import React, { useState } from "react"
import { MdOutlineNotificationsActive, MdOutlineNotificationAdd } from "react-icons/md"
import { Colors } from "../../constants/Colors"
import { ArtistProfile } from "../../data/models/Artist"
import { useWindowSize } from "../../hooks/useWindowSize"
import { ArtistOnListButton } from "./ArtistOnListButton"

export const ArtistSubscribeButton = React.memo((props: {

    //artist: ArtistProfile,
    artistHandle: string,
    style?: React.CSSProperties,
    activeColor?: string,
    activeIconColor?: string,
    inactiveColor?: string,
    inactiveIconColor?: string,
    fadeIfInactive?: boolean
}) => {

    const { artistHandle, style, activeColor, activeIconColor, inactiveColor, inactiveIconColor, fadeIfInactive } = props
    const [ on, setOn ] = useState<boolean | undefined>(false)
    const { scale } = useWindowSize()

    return (
        <ArtistOnListButton key={ artistHandle } circle artistHandle={ artistHandle } list='subscribed' style={{
            ...style, backgroundColor: on ? (activeColor || Colors.BeautifulMagenta) : (inactiveColor || Colors.DarkerBackground),
            opacity: (fadeIfInactive && !on) ? 0.4 : 1
        }} onChange={ setOn }>
            { on ? <MdOutlineNotificationsActive size={ 26 * scale } color={ activeIconColor || 'white' } /> : <MdOutlineNotificationAdd size={ 26 * scale } color={ inactiveIconColor || 'white' } /> }
        </ArtistOnListButton>
    )

})