import { Colors } from "../constants/Colors";
import { ArtistProfile } from "../data/models/Artist";
import { FeedItem } from "../data/models/FeedItem";

type StatusDescriptor = { longLabel: string, shortLabel: string, color: string, gradient: string, noStatus: boolean }

const closed = { longLabel: 'Commissions Closed', shortLabel: 'Closed', color: 'var(--color-grey)', gradient: 'var(--gradient-inactive)', noStatus: false }
const open = { longLabel: 'Open for Commissions', shortLabel: 'Open', color: 'var(--color-success)', gradient: 'var(--gradient-success)', noStatus: false }
const opening = { longLabel: 'Commissions opening soon', shortLabel: 'Opening Soon', color: 'var(--color-blue)', gradient: 'var(--gradient-blue)', noStatus: false }
const unknown = { longLabel: 'Commission status unknown', shortLabel: 'Status Unknown', color: 'var(--color-grey)', gradient: 'var(--gradient-inactive)', noStatus: false }

const prevify = (desc: StatusDescriptor, differentLongLabel: string): StatusDescriptor => ({ ...desc, longLabel: differentLongLabel, color: unknown.color, gradient: unknown.gradient })

const prevClosed = prevify(closed, 'Previous Closing')
const prevOpen = prevify(closed, 'Previous Opening')
const prevOpening = prevify(closed, 'Previous Opening')
const prevUnknown = prevify(closed, 'Previous Status Update')

const none = { ...unknown, noStatus: true }


export const useCommissionStatusDescriptor = (options: { artist?: ArtistProfile, feedItem?: FeedItem }): StatusDescriptor => {

    const { artist, feedItem } = options

    if (feedItem) {
        if (!feedItem.commissionStatus) {
            return none;
        }
        else if(feedItem.artist?.commissionStatusDate && new Date(feedItem.timestamp).getTime() < new Date(feedItem.artist.commissionStatusDate).getTime()) {
            switch (feedItem.commissionStatus) {
                case 'closed': return prevClosed
                case 'open': return prevOpen
                case 'opening-soon': return prevOpening
                case 'unknown': return prevUnknown
            }
        } else {
            switch (feedItem.commissionStatus) {
                case 'closed': return closed
                case 'open': return open
                case 'opening-soon': return opening
                case 'unknown': return unknown
            }
        }
    } else if (artist) {
        switch (artist.commissionStatus) {
            case 'closed': return closed
            case 'open': return open
            case 'opening-soon': return opening
            case 'unknown': return unknown
        }
        return none
    } else {
        return none
    }


}