const _heightHeaderMobile = 60
const _heightFooterMobile = 55
const _heightHeaderDesktop = 100
const _heightFooterDesktop = 0

export const UiConstants = {
    screenInset: 20,
    borderRadius: 30,
    inputHeight: 60,
    feedItemWidth: 600,
    iconButtonSize: 24,
    standardSpacing: 20,
    tightSpacing: 10,

    headFootHeights: {
        mobile: {
            header: _heightHeaderMobile,
            footer: _heightFooterMobile,
            total: _heightHeaderMobile + _heightFooterMobile
        },
        desktop: {
            header: _heightHeaderDesktop,
            footer: _heightFooterDesktop,
            total: _heightHeaderDesktop + _heightFooterDesktop
        },
    }

}