import React, { useMemo } from "react"
import { Col, Container, Row } from "react-grid-system"
import { ArtistPreviewCard } from "../../components/ArtistPreviewCard"
import { Bump } from "../../components/Bump"
import { FeedItemDisplay, FeedSkeleton } from "../../components/Feed"
import { Flex } from "../../components/Flex"
import { SkTabs } from "../../components/SkTabs"
import { TagPicker } from "../../components/TagPicker"
import { BrowseStatusOptions, SearchPayload, useSearchParams } from "./NewBrowseScreen"
import { SkText } from "../../components/SkText"
import { UiConstants } from "../../constants/UiConstants"
import { ArtistSearchResult } from "../../data/models/Artist"
import { FeedItem } from "../../data/models/FeedItem"
import { LoadingBox } from "../../components/LoadingBox"
import { useBrowseScreenSearchContext } from "./useBrowseScreenSearchContext"
import { match } from "ts-pattern"
import { useSkImageTheater } from "../../components/SkImageTheater2"

import './NewBrowseScreenDesktop.scss';

export const NewBrowseScreenDesktop = React.memo(() => {

    const { artistResults, postResults } = useBrowseScreenSearchContext()
    const openTheater = useSkImageTheater(s => s.open)

    return (
        <Container fluid style={{ width: '100%' }}>
            <h1>Explore</h1>
            <Row>

                <Col xs={3}>
                    <LeftColumn />
                </Col>

                <Col xs={9}>

                    <Row>
                        <Col xs={12}>
                            <BrowseScreenTitle />
                        </Col>

                        <Col xs={7}>
                            {postResults === undefined ? <FeedSkeleton /> : null}
                            {postResults?.map(p => <FeedItemDisplay item={p} onImageClick={idx => p.media ? openTheater(p.media, idx) : null} />)}
                        </Col>

                        <Col xs={5}>
                            <Container fluid style={{ width: '100%' }}>
                                <h3>Related Artists</h3>
                                <div className="related-artists">
                                    {artistResults === undefined ? <ArtistSidebarSkeleton /> : null}
                                    {
                                        artistResults?.map(a => (
                                            // <Col xs={6}>
                                                <ArtistPreviewCard artist={a} navOnClick size='normal' showTimeSinceOpen style={{ marginBottom: 10 }} />
                                            // </Col>
                                        ))
                                    }
                                </div>
                            </Container>
                        </Col>
                    </Row>

                </Col>


            </Row>
        </Container>

    )

})

const BrowseScreenTitle = React.memo(() => {

    const [ params ] = useSearchParams()
    const title = useMemo(() => {
        if (params.q) return `Search results for "${params.q}"`
        const tagDesc = params.tags?.length ? ` for ${params.tags.join(', ')}` : '';
        //@ts-ignore
        return match(params.status)
            .when(v => v ? v.includes('open') : false, v => `Current Openings${tagDesc}`)
            .when(v => v ? v.includes('opening-soon') : false, v => `Upcoming Openings${tagDesc}`)
            .otherwise(v => `Recent Updates${tagDesc}`)
    }, [ params ])

    return <h2>{ title }</h2>

})

const ArtistSidebarSkeleton = React.memo(() => {

    return (
        <>
        <Col xs={ 6 } style={{ marginBottom: 10 }}><LoadingBox height={ 200 } /></Col>
        <Col xs={ 6 } style={{ marginBottom: 10 }}><LoadingBox height={ 200 } /></Col>
        <Col xs={ 6 } style={{ marginBottom: 10 }}><LoadingBox height={ 200 } /></Col>
        <Col xs={ 6 } style={{ marginBottom: 10 }}><LoadingBox height={ 200 } /></Col>
        <Col xs={ 6 } style={{ marginBottom: 10 }}><LoadingBox height={ 200 } /></Col>
        </>
    )

})

const LeftColumn = React.memo(() => {

    const [params, setParams] = useSearchParams()

    return (
        <Flex align='stretch'>

            <SkTabs
                variant='vertical'
                options={BrowseStatusOptions}
                value={params.status?.[0] || 'all'}
                onSelect={v => setParams({ status: [v] })}
            />
            <Bump h={20} />
            <TagPicker wrap selected={params.tags} onSelect={ tags => setParams({ tags }) } />
        </Flex>
    )

})