import { CommissionStatus } from "../data/models/Artist"

export const Colors = {
    LightBackground: 'white',
    LessLightBackground: '#F3F3F3',
    DarkBackground: '#23252B',
    MidDarkBackground: '#1c1d22',
    DarkerBackground: '#121215',
    HeaderBackground: 'rgba(35, 37, 43, 0.8)',
    SuccessGreen: '#008555',
    FailureRed: '#A51414',
    PrimaryBlue: '#008BD9',
    PrimarierBlue: '#00a3ff',
    BeautifulMagenta: '#9c27b0',
    InactiveGray: '#3C414E',
    Gradient: 'linear-gradient(51deg, rgba(199,0,217,1) 0%, rgba(0,139,217,1) 100%)' ,
    SuccessGradient: 'linear-gradient(121deg, rgba(0,133,85,1) 0%, rgba(0,184,75,1) 100%)',
    BlueGradient: 'linear-gradient(121deg, rgb(0 139 217) 0%, rgb(0 78 159) 100%)',
    InactiveGradient: 'linear-gradient(121deg, rgb(44 47 54) 0%, rgb(67 76 86) 100%)'
}

export const CommissionColor = (status: CommissionStatus): string => {
    switch (status) { 
        case 'closed': return Colors.InactiveGray;
        case 'open': return Colors.SuccessGreen;
        case 'opening-soon': return Colors.PrimaryBlue;
        case 'unknown': default: return Colors.InactiveGray;
    }
}

export const CommissionGradient = (status: CommissionStatus): string => {
    switch (status) { 
        case 'closed': return Colors.InactiveGradient;
        case 'open': return Colors.SuccessGradient;
        case 'opening-soon': return Colors.BlueGradient;
        case 'unknown': default: return Colors.InactiveGradient;
    }
}