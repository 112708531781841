import React, { useMemo } from "react";
import { RelevantTags, Tags } from "../constants/RelevantTags";
import { UiConstants } from "../constants/UiConstants";
import { Flex } from "./Flex";
import { SkPill } from "./SkPill";
import { TagPicker } from "./TagPicker";

interface TagEditorProps {
    tags: string[],
    onUpdate: (tags: string[]) => void
    style?: React.CSSProperties
}

export const TagEditor = React.memo((props: TagEditorProps) => {

    const { style, tags, onUpdate } = props
    const tagSet = useMemo(() => new Set(tags), [ tags ])

    const handleToggle = (t: string) => tagSet.has(t) ? handleRemove(t) : handleAdd(t)
    const handleRemove = (t: string) => onUpdate(tags?.filter(x => x !== t) || [])
    const handleAdd = (t: string) => onUpdate([ ...tags, t ])

    const randos = useMemo(() => tags?.filter(t => !Tags.AllSet.has(t)) || [], [ tags ])

    return (
        <Flex style={ style }>
            {
                Tags.TopLevelTags.map(t =>  {

                    if (tagSet.has(t)) return (
                        <Flex row style={{ margin: '20px 0', flexWrap: 'wrap' }}>
                            <SkPill label={ t } textVariant='descriptor' selected onClick={ () => handleRemove(t) } />
                            {
                                RelevantTags[t].children.map(c => (
                                    <SkPill label={ c } textVariant='descriptor' selected={ tagSet.has(c) } onClick={ () => handleToggle(c) } />
                                ))
                            }
                        </Flex>
                    )

                    return (
                        <Flex row>
                            <SkPill label={ t } textVariant='descriptor' onClick={ () => handleAdd(t) } />
                        </Flex>
                    )

                })
            }
            <Flex row style={{ flexWrap: 'wrap' }}>
                {
                    randos.map(c => (
                        <SkPill label={ c } textVariant='descriptor' selected={ tagSet.has(c) } onClick={ () => handleToggle(c) } />
                    ))
                }
            </Flex>
        </Flex>
    )

})
