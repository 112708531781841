import Axios from 'axios'
import { SkResponse } from '../models/SkResponse'
import { MakeUrl } from './Urls'

export const GetTelegramPairingCodeAction = async (): Promise<SkResponse<{ code: string }>> => {
    return await Axios.put(MakeUrl('/notifs/tg/code'))
}

export const SetWebPushSubscriptionAction = async (subscription: PushSubscriptionJSON): Promise<SkResponse<void>> => {
    return await Axios.put(MakeUrl('/notifs/wpush/configure'), subscription)
}

export const RemoveWebPushSubscriptionAction = async (): Promise<SkResponse<void>> => {
    return await Axios.delete(MakeUrl('/notifs/wpush/remove'))
}

export const RemoveTelegramSubscriptionAction = async (): Promise<SkResponse<void>> => {
    return await Axios.delete(MakeUrl('/notifs/tg/remove'))
}