import Axios from 'axios'
import { FeedItem } from '../models/FeedItem'
import { SkResponse } from '../models/SkResponse'
import { PostSearchParams } from '../requests/PostSearchParams'
import { MakeUrl } from './Urls'

export const GetFeedAction = async (): Promise<SkResponse<FeedItem[]>> => {
    return await Axios.get(MakeUrl('/feed'))
}

export const GetPostAction = async (postId: string): Promise<SkResponse<FeedItem>> => {
    return await Axios.get(MakeUrl(`/feed/post/${postId}`))
}

export const GetArtistFeedAction = async (artistHandle: string): Promise<SkResponse<FeedItem[]>> => {
    return await Axios.get(MakeUrl(`/artists/${artistHandle}/feed`))
}

export const OpenArtistsFeedAction = async (tags?: string[]): Promise<SkResponse<FeedItem[]>> => {
    return await Axios.get(MakeUrl(`/feed/open`, tags ? { tags } : undefined))
}

export const OpeningSoonArtistsFeedAction = async (tags?: string[]): Promise<SkResponse<FeedItem[]>> => {
    return await Axios.get(MakeUrl(`/feed/opening-soon`, tags ? { tags } : undefined))
}

export const SearchPostsAction = async (filters: PostSearchParams): Promise<SkResponse<FeedItem[]>> => {
    return await Axios.get(MakeUrl(`/feed/search`, filters))
}