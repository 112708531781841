import { GetAllTagsAction } from "../data/actions/TagActions"

const Stuff = [ 'Digital Art', 'Fursuits', 'Accessories', 'Fursuit Accessories', '3D', 'Services' ]
const FursuitStuff = [ 'Digitigrade Fursuits', 'Plantigrade Fursuits', 'Plush Suits', 'Synthsuits', 'Animatronics' ]
const ArtStuff = [ 'Ref Sheets', 'Telegram Stickers', 'Icons', 'Animations', 'Comics', 'Streaming Avatars', 'NSFW' ]
const AccessorySuff = [
    'Charms', 'Enamel Pins', 'Physical Stickers', 'Magnets', 'Buttons', 'Decals', 'Mouse Pads',
    'Flags', 'Dakimakura', 'Figurines', 'Plushies', 'Custom ID Cards', 'Kigurumi'
]
const FursuitAccessorySuff = [ 'Bandanas', 'Fursuit Harnesses', 'Fursuit Collars', 'Fursuit Leashes', 'Props' ]
const ThreeDStuff = [ '3D Avatars', '3D Animations' ]
const Services = [ 'Custom ID Badges', 'Character Design', 'Fursuit Repair', 'Fursuit Modification' ]

const Suitability = [ 'SFW', 'NSFW' ]

// Might add these in at some point, idk
//const Styles = [ 'Realistic', 'Toony', 'Quirky', 'Cute', 'Mature', 'Stylized',  ]

let dbTags: string[] = []
let allTags: string[] = []
let tagsInitialized = false

export const InitializeTags = async () => {
    if (tagsInitialized) return;
    try {
        const res = await GetAllTagsAction()
        tagsInitialized = true
        dbTags = res.data || []
    } catch (e) {
        console.warn('Unable to download API tags, try again later...')
    }
    const top  = RelevantTags._.children
    const rest = top.filter(t => !!RelevantTags[t]?.children).flatMap(t => RelevantTags[t].children as string[]).concat(dbTags)
    allTags = UniqueOrderPreserved([ ...top, ...rest ])
}

type TagDef = {
    children: string[]
}
export const RelevantTags: Record<string, TagDef> = {
    _: { children: Stuff }, // Top Level
    Fursuits: { children: FursuitStuff },
    'Digital Art': { children: ArtStuff },
    Accessories: { children: AccessorySuff },
    'Fursuit Accessories': { children: FursuitAccessorySuff },
    '3D': { children: ThreeDStuff },
    Services: { children: Services }
}

const UniqueOrderPreserved = <TItem>(items: TItem[]) => {
    const found = new Set<TItem>();
    return items.reduce((list, next) => {
        if (!found.has(next)) {
            found.add(next)
            return [ ...list, next ]
        }
        return list
    }, [])
}

const allPredefined = Object.entries(RelevantTags).flatMap(([ key, value ]) => [ key, ...value.children ])

export const Tags = {

    All: allPredefined,
    AllSet: new Set(allPredefined),
    TopLevelTags: RelevantTags._.children,
    TopTagSet: new Set(RelevantTags._.children),

    Refine: (selected?: string[]) => {

        // Start with selected tags, and then top-level tags
        let list = selected?.length ? selected : RelevantTags._.children

        // Add in all selected's children
        selected?.filter(s => s !== '_' && RelevantTags[s]?.children).forEach(s => {
            list = list.concat(RelevantTags[s].children)
        })

        // Get the remaining children. Unique them all according to first appearance
        return UniqueOrderPreserved([ ...list, ...allTags ])

    },

    InferSearchTags: (selected: string[]): { tags: string[], tagMatch: 'all' | 'some' } => {
        const tagSet = new Set(selected)
        const topLevelsUnspecified = selected.filter(t => Tags.TopTagSet.has(t))
            .filter(t => !RelevantTags[t]?.children?.some(c => tagSet.has(c)))
        const tagMatch = topLevelsUnspecified.length ? 'some' : 'all'
        const topLevelPossibilities = topLevelsUnspecified.flatMap(t => RelevantTags[t]?.children)
        const theRest = selected.filter(t => !topLevelsUnspecified.includes(t))
        const inferTags = [ ...topLevelsUnspecified, ...topLevelPossibilities, ...theRest ]
        return { tags: inferTags, tagMatch }
    }
}
