import { ArtistSearchParams } from "./requests/ArtistSearchParams";
import { PostSearchParams } from "./requests/PostSearchParams";

export const QueryKeys = {
    _All: () => [],
    Nothing: () => [ 'nothing' ], // Use for when inputs aren't ready yet
    User: {
        Current: {
            _All: () => [ 'current-user' ],
            Profile: () => [ 'current-user', 'profile' ],
            Feed: () => [ 'current-user', 'feed' ],
            FollowedArtists: () => [ 'current-user', 'followed-artists' ],
            SubscribedArtists: () => [ 'current-user', 'subscribed-artists' ],
            MutedArtists: () => [ 'current-user', 'muted-artists' ],

            FollowedArtistHandles: () => [ 'current-user', 'followed-artists', 'handles' ],
            SubscribedArtistHandles: () => [ 'current-user', 'subscribed-artists', 'handles' ],
            MutedArtistHandles: () => [ 'current-user', 'muted-artists', 'handles' ]
        }
    },
    Search: (query: string) => [ 'search', query ],
    Artist: {
        _All: () => [ 'artist' ],
        _AllHandle: (handle: string) => [ 'artist', handle ],
        Profile: (handle: string) => [ 'artist', handle, 'profile' ],
        Feed: (handle: string) => [ 'artist', handle, 'feed' ],
        Search: (filters: ArtistSearchParams) => [ 'artist', ...Keyify(filters) ],
        Intro: () => [ 'artist', 'intro' ]
    },
    Posts: {
        Search: (filters: PostSearchParams) => [ 'posts', ...Keyify(filters) ],
        Open: (tags) => [ 'posts', 'open', ...Keyify({ tags }) ],
        OpeningSoon: (tags) => [ 'posts', 'opening-soon', ...Keyify({ tags }) ],
        Get: (postId: string) => [ 'posts', postId ]
    },
    Tags: {
        _All: () => [ 'tags' ],
        All: () => [ 'tags', 'all' ]
    },
    Admin: {
        _All: () => [ 'admin' ],
        AppStats: () => [ 'admin', 'app-stats' ]
    },
    Reports: {
        _All: () => [ 'reports' ],
        Open: () => [ 'reports', 'open' ]
    },
    Auth: {
        _All: () => [ 'auth' ],
        Token: () => [ 'auth', 'token' ],
    }
}

const Keyify = (obj: object) => {
    let e = Object.entries(obj);
    e = e.sort()
    return e.map(([ key, value ]) => `${key}=${encodeURIComponent(value)}`)
}