import { useQuery, useQueryErrorResetBoundary } from "@tanstack/react-query";
import { report } from "process";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { Bump } from "../../components/Bump";
import { SkCard } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { ScreenWrapper } from "../../components/ScreenWrapper";
import { SkButton, SkCircleButton } from "../../components/SkButton";
import { SkImageTheater } from "../../components/SkImageTheater";
import { SkText } from "../../components/SkText";
import { UserImage } from "../../components/UserImage";
import { CommissionColor } from "../../constants/Colors";
import { SkRoutes } from "../../constants/Routes";
import { UiConstants } from "../../constants/UiConstants";
import { ApproveReport, DenyReport, GetOpenReports } from "../../data/actions/ReportActions";
import { Report } from "../../data/models/Report";
import { QueryKeys } from "../../data/QueryKeys";
import { FormatDate } from "../../utils/FormatDate";

export const ReportsScreen = React.memo(() => {

    const reports = useQuery(QueryKeys.Reports.Open(), () => GetOpenReports())
    const nReports = reports?.data?.data?.length || 0 

    return (
        <ScreenWrapper>
            <Flex>
                <SkText variant='headline'>Reports</SkText>
                <SkText variant='c1' faded>{ nReports } report{ nReports === 1 ? '' : 's' }</SkText>
            </Flex>

            <Bump h={ UiConstants.standardSpacing * 3 } />

            <Container fluid>
                <Row>
                {
                    reports.data?.data?.map(r => (
                        <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 4 }>
                            <ReportView report={ r } />
                        </Col>
                    ))
                }
                </Row>
            </Container>

        </ScreenWrapper>
    )

})

const ReportView = React.memo((props: { report: Report }) => {

    const { report } = props

    switch (report.type) {
        case 'artist-status': return <ArtistStatusReport report={ report } />
        default: return (
            <SkCard>
                <SkText variant='c2' faded>Unknown report type { report.type }</SkText>
            </SkCard>
        )
    }

})

const ArtistStatusReport = React.memo((props: { report: Report }) => {

    const { report } = props
    const nav = useNavigate()
    const tweetProvided = report.data?.tweetUrl;

    return (
        <SkCard style={{ marginBottom: UiConstants.tightSpacing }}>

            <SubmitterInfo report={ report } />
            <Bump h={ UiConstants.tightSpacing } />
            <SkText variant='c1'>
                <strong>{ report.data?.artistHandle }</strong>'s status
                should be <strong style={{ color: CommissionColor(report.data?.correctedStatus) }}>{ report.data?.correctedStatus }</strong> according
                to the following tweet
            </SkText>

            <Bump h={ UiConstants.standardSpacing } />

            <SkButton title={ tweetProvided ? 'View Tweet' : 'No Tweet' } href={ report.data?.tweetUrl } variant={ tweetProvided ? 'primary' : 'text' } />
            <Bump h={ UiConstants.tightSpacing } />
            <SkText variant='c2' faded style={{ wordWrap: 'break-word' }}>
                { report.data?.tweetUrl }
            </SkText>
            <Bump h={ UiConstants.standardSpacing } />

            <SkButton variant='text' title={ `View ${ report.data?.artistHandle }'s Profile` } onClick={ () => nav(SkRoutes.Artist.Show(report.data?.artistHandle)) } />

            <Bump h={ UiConstants.standardSpacing * 2 } />

            <ReportActionButtons report={ report } />

        </SkCard>
    )

})

const SubmitterInfo = React.memo((props: { report: Report }) => {

    const { report } = props

    return (
        <Flex row>
            <UserImage url={ report.submitterImageUrl } />
            <Bump w={ UiConstants.tightSpacing } />
            <Flex>
                <SkText variant='name'>{ report.submitterName }</SkText>
                <SkText variant='c2' faded>{ FormatDate.adaptiveTime(report.timestamp) }</SkText>
            </Flex>
        </Flex>
    )

})

const ReportActionButtons = React.memo((props: { report: Report }) => {

    const { report } = props

    return (
        <Flex row>
            <SkButton title='Approve' variant='primary' onClick={ () => ApproveReport(report.id) } />
            <Bump w={ UiConstants.standardSpacing } />
            <SkButton title='Deny' variant='secondary' onClick={ () => DenyReport(report.id) } />
        </Flex>
    )

})