import React from "react";
import { CgChevronLeft, CgList } from "react-icons/cg";
import { TbTelescope } from "react-icons/tb";
import { MdHome } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Colors } from "../constants/Colors";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { useAuth } from "../hooks/useAuth";
import { useNavRoute } from "../hooks/useNavRoute";
import { useWindowSize } from "../hooks/useWindowSize";
import { Bump } from "./Bump";
import { Flex } from "./Flex";
import { Logo } from "./Logo";
import { SkButton } from "./SkButton";
import { SkTextInput } from "./SkTextInput";
import { UserImage } from "./UserImage";
import { DesktopSearchBox } from "./Search/DesktopSearchBox";

import "./Header.scss";

export const Header = React.memo(() => {

    const nav = useNavigate();
    const auth = useAuth();
    const { isMobile } = useWindowSize();

    const route = useNavRoute();
    const eSize = isMobile ? UiConstants.headFootHeights.mobile.header : UiConstants.headFootHeights.desktop.header;

    if (!auth.loggedIn) return null;

    // if (isMobile) return (
    //     <header className="mobile-header">
    //         {/* <Flex row justify='space-between' align='center' style={{ width: '100%', height: eSize, display: 'flex', padding: '0 20px', boxSizing: 'border-box' }}> */}
    //             <MobileBackButton />
    //             <Logo variant='word' size={ 20 } />
    //             <Nothing />
    //         {/* </Flex> */}
    //     </header>
    // );

    return (
        <>
            <header className="mobile-header" id="header">
                {/* <Flex row justify='space-between' align='center' style={{ width: '100%', height: eSize, display: 'flex', padding: '0 20px', boxSizing: 'border-box' }}> */}
                    <MobileBackButton />
                    <Logo variant='word' size={ 20 } />
                    <Nothing />
                {/* </Flex> */}
            </header>

            <header className="desktop-header">
                <div className="header-left">
                    <Logo />

                    <SkButton 
                        className={`header-button ${route === 'home' ? 'active' : ''}`}
                        variant='text' 
                        // style={{ opacity: route === 'search' ? 1 : 0.5 }} 
                        href={SkRoutes.Home()}
                        onClick={(e) => { nav(SkRoutes.Home()); e.preventDefault(); }} 
                        icon={ <MdHome color='white' size={ 30 } /> } 
                        title='Home' />

                    <SkButton 
                        className={`header-button ${route === 'explore' ? 'active' : ''}`}
                        variant='text' 
                        // style={{ opacity: route === 'search' ? 1 : 0.5 }} 
                        href={SkRoutes.Browse({})}
                        onClick={(e) => { nav(SkRoutes.Browse({})); e.preventDefault(); }} 
                        icon={ <TbTelescope color='white' size={ 30 } /> } 
                        title='Explore' />
                    <SkButton 
                        className={`header-button ${route === 'following' ? 'active' : ''}`}
                        variant='text' 
                        // style={{ opacity: route === 'following' ? 1 : 0.5 }} 
                        href={SkRoutes.Following()}
                        onClick={(e) => { nav(SkRoutes.Following()); e.preventDefault(); }}
                        icon={ <CgList color='white' size={ 30 } /> } 
                        title='Following' />
                </div>
                <div className="header-right">
                    <DesktopSearchBox />
                    <UserImage navOnClick size={40} />
                </div>
            </header>

            {/* <header className="desktop-header">
                <Flex row justify='space-between' align='center' style={{ width: '100%', height: eSize, display: 'flex', padding: '0 20px', boxSizing: 'border-box' }}>
                    <Flex row align='center'>
                        <Logo />
                        <Bump w={ 40 } />
                        <SkButton variant='text' style={{ opacity: route === 'search' ? 1 : 0.5 }} onClick={ () => nav(SkRoutes.Browse({})) } icon={ <TbTelescope color='white' size={ 30 } /> } title='Explore' />
                        <Bump w={ 40 } />
                        <SkButton variant='text' style={{ opacity: route === 'following' ? 1 : 0.5 }} onClick={ () => nav(SkRoutes.Following()) } icon={ <CgList color='white' size={ 30 } /> } title='Following' />
                    </Flex>
                    <Flex row align='center'>
                        <DesktopSearchBox />
                        <Bump w={ 20 } />
                        <UserImage navOnClick size={ eSize - 35 } />
                    </Flex>
                </Flex>
            </header> */}
        </>
    );
})

const MobileBackButton = React.memo(() => {

    const route = useNavRoute()
    if (route !== 'other') return <Nothing />
    return (
        <div className="mobile-back">
            <CgChevronLeft size={ 30 }  onClick={() => window.history.back()} color='white' />
        </div>
    )

});

const Nothing = () => (<div></div>)
