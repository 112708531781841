import React, { useEffect } from "react";
import { NotificationMethod } from "../../constants/NotificationMethod";
import { UiConstants } from "../../constants/UiConstants";
import { Notifications } from "../../utils/Notifications";
import { Flex } from "../Flex";
import { SkText } from "../SkText";
import { SkButton, SkButtonVariant } from "../SkButton";
import { FaCircleCheck, FaCirclePause } from "react-icons/fa6";

import "./NotificationMethodCard.scss";

export const NotificationMethodCard = React.memo((props: { method: NotificationMethod, onClick?: () => void, noPadding?: boolean }) => {

    const meta = Notifications.useNotificationMethodStatus(props.method);

    let variant: SkButtonVariant = 'outlined';
    let buttonText = 'Unknown';

    switch(meta.status) {
        case 'active':
            variant = 'primary';
            buttonText = `Configure ${meta.name} notifications`;
            break;
        case 'not-configured':
            variant = 'outlined';
            buttonText = `Set up ${meta.name} notifications`;
            break;
    }

    return (
        <div className="notification-method-card">
            <SkButton variant={variant} onClick={props.onClick} icon={meta.icon}>{buttonText}</SkButton>
            {
                meta.status === 'different-device' &&
                <div className="status-message">
                    {meta.name} notifications are set up on a different device
                </div>
            }
            {
                meta.status === 'active' &&
                <div className="status-message">
                    <FaCircleCheck size={20} style={{ color: 'var(--color-success)' }} />
                    {meta.name} notifications are set up!
                </div>
            }
            {
                meta.status === 'paused' &&
                <div className="status-message">
                    <FaCirclePause size={20} style={{ color: 'var(--color-warning)' }} />
                    {meta.name} notifications are paused
                </div>
            }
        </div>
    )

    // return (
    //     <Flex align='center' style={{
    //             backgroundColor: meta.statusColor, borderRadius: UiConstants.borderRadius, minWidth: 140, maxWidth: 140, height: 180, marginRight: props.noPadding ? 0 : 20,
    //             padding: UiConstants.standardSpacing, cursor: props.onClick ? 'pointer' : undefined
    //         }}
    //         onClick={ props.onClick }
    //         >
    //         <Flex justify='center' style={{ flex: 1 }}>
    //             { meta.icon }
    //         </Flex>
    //         <SkText variant='c1' style={{ fontWeight: 'bold', marginBottom: UiConstants.tightSpacing }}>{ meta.name }</SkText>
    //         <SkText variant='descriptor' style={{ fontWeight: 'bold' }}>{ meta.sublabel }</SkText>
    //     </Flex>
    // )

})