import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { BsExclamationOctagon, BsHeart } from "react-icons/bs";
import { FaGavel, FaRobot } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
import { ArtistPreviewCard } from "../components/ArtistPreviewCard";
import { Flex } from "../components/Flex";
import { SkButton } from "../components/SkButton";
import { SkModal } from "../components/SkModal";
import { SkText } from "../components/SkText";
import { Colors } from "../constants/Colors";
import { NotificationMethod } from "../constants/NotificationMethod";
import { UiConstants } from "../constants/UiConstants";
import { FollowArtist, GetFollowedArtists, GetIntroArtists, UnfollowArtist } from "../data/actions/ArtistActions";
import { SetUserProgress } from "../data/actions/UserActions";
import { QueryKeys } from "../data/QueryKeys";
import { ToggleModel } from "../hooks/useToggle";
import { useWindowSize } from "../hooks/useWindowSize";
import { Notifications } from "../utils/Notifications";
import { Bump } from "./Bump";
import { SkCard } from "./Card";
import { NotificationMethodCard } from "./Notifications/NotificationMethodCard";
import { NotificationSetupForm } from "./Notifications/NotificationSetupForm";
import { RxOpenInNewWindow } from "react-icons/rx";

import './WelcomeModal.scss';

const encouragedSize = 5
type Page = 'disclaimers' | 'artist-pick' | 'notification-methods' | 'notification-setup'

export const WelcomeModal = React.memo((props: { toggle: ToggleModel }) => {

    const { toggle } = props
    const [ page, setPage ] = useState<Page>('disclaimers')
    const [ setupMethod, setSetupMethod ] = useState<NotificationMethod>('telegram')
    const meta = Notifications.useNotificationMethodStatus(setupMethod)

    const content = useMemo(() => {
        switch(page) {
            case 'disclaimers': return <DisclaimerAndConsentPage onAccept={ () => setPage('artist-pick') } />
            case 'artist-pick': return <PickArtistsPage onNext={ () => setPage('notification-methods') } />
            case 'notification-methods': return <NotificationsMethodsPage onNext={ (method: NotificationMethod) => {
                setSetupMethod(method)
                setPage('notification-setup')
            }} onDismiss={ toggle.close } />
            case 'notification-setup': return <div style={{ margin: '0 20px' }}><NotificationSetupForm method={ setupMethod } onDone={ toggle.close } /></div>
        }
    }, [ page ])

    const title = useMemo(() => setupMethod ? `${meta.name} Notification Setup` : undefined, [ meta, setupMethod ])

    return (
        <SkModal open={ toggle.isOpen } onClose={ toggle.close } title={ title } closerStyle="none" closeOnBackdropClick={false}>
            { content }
        </SkModal>
    )
})

const disclaimerCards: DisclaimerCardModel[] = [
    {
        title: "Early Access",
        message: (<span>Skrunkle is a <em>long</em> way from being complete. If you find something wrong, please let us know on Bluesky at <a href="https://bsky.app/profile/skrunkle.com" target="_blank" style={{  color: 'white', fontWeight: 'bold' }}>@skrunkle.com</a>, or on Twitter (X) at <a href="https://twitter.com/SkrunkleArt" target="_blank" style={{ color: 'white', fontWeight: 'bold' }}>@SkrunkleArt</a></span>),
        icon: <BsHeart color='white' size={ 60 } />,
        primaryAction: { label: 'Next' },
        color: Colors.SuccessGreen
    },
    {
        title: "Beep Boop",
        message: (<span>We detect commission status updates with schmancy text analytics, and while the algorithm is constantly improving, sometimes we don't quite get it right.</span>),
        icon: <FaRobot color='white' size={ 60 } />,
        primaryAction: { label: "I'm cool with that" },
        color: Colors.PrimaryBlue
    },
    {
        title: "Just Bsky For Now",
        message: (<span>For now, we're only tracking commission status updates on <strong>Bluesky</strong> but we're hard at work hooking up to more sites!</span>),
        icon: <FaBluesky color='white' size={ 60 } />,
        primaryAction: { label: 'Continue' },
        color: Colors.PrimaryBlue
    },
    {
        title: "We Don't Endorse Artist Content",
        message: (<span>Posts you view here are mirrored from artists' social media posts, and are the sole responsibility of the artist.</span>),
        icon: <FaGavel color='white' size={ 60 } />,
        primaryAction: { label: "Understood" },
        color: Colors.BeautifulMagenta
    },
    {
        title: "Skrunkle Policies (18+)",
        serious: true,
        message: (
            <div>
                <p>
                    <strong>Skrunkle is not for minors. If you are under 18 years of age, you may not use Skrunkle.</strong>
                </p>
                <p>
                    You must also agree to our&nbsp;
                    <a href="/terms/index.html" target="_blank">Terms of Service<RxOpenInNewWindow /></a>&nbsp;
                    and&nbsp;
                    <a href="/privacy/index.html" target="_blank">Privacy Policy<RxOpenInNewWindow /></a>.
                </p>
            </div>
        ),
        icon: <BsExclamationOctagon color='white' size={ 60 } />,
        primaryAction: { label: "I'm 18 or older, and agree to the policies linked above", colorOverride: Colors.PrimaryBlue },
        secondaryAction: { label: "I am not 18 or older, and/or disagree with the policies", callback: () => { window.location.href = 'https://www.google.com/' } },
        color: Colors.FailureRed
    }
]

const DisclaimerAndConsentPage = React.memo((props: { onAccept: () => void }) => {

    const { onAccept } = props;
    const [ cardIndex, setCardIndex ] = useState(0);
    const model = useMemo(() => disclaimerCards[cardIndex], [ disclaimerCards, cardIndex ])
    const handlePrimary = () => {
        model?.primaryAction?.callback?.()
        if (cardIndex === disclaimerCards.length - 1) onAccept()
        else setCardIndex(cardIndex + 1)
    }

    return (
        <Flex className="welcome-modal">
            {/* <Flex align='center' style={{ display: model?.serious ? 'none' : 'flex' }}> */}
                <div className="welcome-title" style={{ display: model?.serious ? 'none' : undefined }}>
                    <h1>Hiya! ✨</h1>
                    <p>We're so excited you're here! Skrunkle lets you know when your favorite furry artists open for commissions. But first, some important things to note:</p>
                </div>
                {/* <SkText variant='headline' style={{ marginTop: 10 }}>Hiya! ✨</SkText> */}
                {/* <SkText variant='c1' style={{ marginTop: 20 }} faded>We're so excied you're here! Skrunkle lets you know when your favorite furry artists open for commissions. But first, some important things to note:</SkText> */}
                {/* <Bump h={ UiConstants.standardSpacing * 2 } /> */}
            {/* </Flex> */}

            <DisclaimerCard model={ model } />
            {/* <Bump h={ UiConstants.standardSpacing * 2 } /> */}
            
            <div className="welcome-buttons">
                <SkButton title={ model?.primaryAction?.label } style={{ backgroundColor: model?.primaryAction.colorOverride || model?.color }} onClick={ handlePrimary } />
                { model?.secondaryAction ? <SkButton variant='secondary' title={ model.secondaryAction.label } onClick={ model.secondaryAction.callback } /> : null }
            </div>

        </Flex>
    )

})

type DisclaimerCardModel = {
    icon: JSX.Element
    title: string
    message: JSX.Element
    color: string
    serious?: boolean
    primaryAction: { label: string, callback?: () => void, colorOverride?: string }
    secondaryAction?: { label: string, callback?: () => void }
}
const DisclaimerCard = React.memo((props: { model: DisclaimerCardModel }) => {

    const { icon, title, message, color, serious } = props.model
    const { isMobile } = useWindowSize()

    return (
        <SkCard style={{ backgroundColor: color }}>
            <Flex row={ !isMobile } align='center'>
                <div style={{ padding: '0 20px' }}>
                    { icon }
                </div>
                <Bump w={ isMobile ? 0 : UiConstants.standardSpacing } h={ isMobile ? UiConstants.standardSpacing : 0 } />
                <Flex>
                    <h3>{title}</h3>
                    <p>{message}</p>
                </Flex>
            </Flex>
        </SkCard>
    )

})

const NotificationsMethodsPage = React.memo((props: { onNext: (method: NotificationMethod) => void, onDismiss: () => void }) => {

    const { onNext, onDismiss } = props
    const { isMobile } = useWindowSize()

    return (
        <Flex className="welcome-modal">

            <div className="welcome-title">
                <h1>Oh! One Last Thing</h1>
                <p>How should we notify you that artists are open for commissions?</p>
            </div>

            <Flex row justify={ isMobile ? undefined : 'center' }
                style={{
                    flexWrap: isMobile ? undefined : 'wrap', overflowX: isMobile ? 'scroll' : undefined,
                    paddingLeft: isMobile ? 20 : 0
                }}>
                    <NotificationMethodCard method='telegram' onClick={ () => onNext('telegram') } />
                    <NotificationMethodCard method='webpush' onClick={ () => onNext('webpush') } noPadding />
                    <Bump w={ 70 } mobileOnly />
            </Flex>

            <Flex align='center' style={{ padding: '20px 20px 0 20px' }}>
                <SkText variant='descriptor' faded>You can update these settings from the profile screen anytime, so no pressure.</SkText>
            </Flex>

            <SkButton title='Maybe Later' variant='secondary' onClick={ onDismiss } style={{ margin: '40px 20px 0 20px', width: undefined }} />

        </Flex>
    )

})

const PickArtistsPage = React.memo((props: { onNext: () => void }) => {

    const { onNext } = props
    const [ page, setPage ] = useState(0)
    const { isMobile } = useWindowSize()
    const introPageSize = useMemo(() => isMobile ? 4 : 6, [ isMobile ])
    const artistsRaw = useQuery(QueryKeys.Artist.Intro(), GetIntroArtists)
    const artists = useMemo(() => artistsRaw?.data?.data?.filter(a => !!a?.handle) || [], [ artistsRaw ])
    const lastPage = useMemo(() => Math.floor(artists?.length / introPageSize) - 1, [ artists ])

    const [ added, setAdded ] = useState<string[]>([])
    const following = useQuery(QueryKeys.User.Current.FollowedArtists(), GetFollowedArtists)
    const followedSet = useMemo(() => new Set(following.data?.data?.map(a => a.handle).concat(added)), [ following, added ])

    const window = useMemo(() => {
        if (!artists?.length) 
            return [];
        //if (isMobile) 
        //    return artists;
        
        return artists.slice(page * introPageSize, (page + 1) * introPageSize);
    }, [ page, artists, isMobile, introPageSize ])

    const handleShowMore = () => { setPage(page >= lastPage ? 0 : page + 1) }

    const handleSubscribe = (handle: string) => {
        if (followedSet?.has(handle)) {
            UnfollowArtist(handle)
            setAdded(added.filter(a => a !== handle))
        } else {
            FollowArtist(handle)
            setAdded([ ...added, handle ])
        }
    }

    const handleComplete = () => {
        SetUserProgress('welcomed')
        onNext()
    }

    let needsMore = followedSet.size < encouragedSize
    const continueLabel = useMemo(() => {
        if (needsMore) return `Pick ${ encouragedSize - followedSet.size } More!`
        return 'Let\'s Get Skrunkin!'
    }, [ followedSet.size, needsMore ])

    return (
        <Flex className="welcome-modal">
            <div className="welcome-title">
                <h1>🎆 Heck Yeah!</h1>
                <p>Welcome aboard! To start off, tell us about some your favorite artists.</p>
            </div>

            <div className="welcome-artists">
                {
                    window.map((a, idx) => (
                        <ArtistPreviewCard variant="follow-only" size='fixed-width' artist={ a } onClick={ () => handleSubscribe(a?.handle) } checked={ followedSet.has(a?.handle) } />
                    ))
                }
            </div>

            {/* <Flex row justify={ isMobile ? undefined : 'center' }
                style={{
                    flexWrap: isMobile ? undefined : 'wrap', overflowX: isMobile ? 'scroll' : undefined,
                    paddingLeft: isMobile ? 20 : 0
                }}>
                {
                    window.map((a, idx) => (
                        <ArtistPreviewCard size='fixed-width' artist={ a } style={{ marginBottom: 10, marginRight: (idx % 2 && !isMobile) ? 0 : 10 }}
                            onClick={ () => handleSubscribe(a?.handle) } checked={ followedSet.has(a?.handle) } />
                    ))
                }
            </Flex> */}

            <Flex style={{ margin: '10px 20px 0 20px' }}>
                <Flex row justify='center' style={{  width: '100%' }}>
                    {/* { isMobile ? null : <SkButton variant='secondary' onClick={ handleShowMore } title='Show Me More' style={{ maxWidth: 200 }} /> } */}
                    <SkButton variant='secondary' onClick={ handleShowMore } title='Show Me More' style={{ maxWidth: 200 }} />
                </Flex>

                {/* <Flex row={ !isMobile } justify='space-between' align='center' style={{ width: '100%', marginTop: 40 }}>
                    <SkButton title={ continueLabel } onClick={ handleComplete } disabled={ needsMore } style={{ maxWidth: needsMore ? 250 : undefined, opacity: needsMore ? 0.6 : 1 }} />
                    { needsMore ? <SkText variant='control-label' onClick={ onNext } faded style={{ padding: 20, cursor: 'pointer' }}>Ask Me Later</SkText> : null }
                </Flex> */}
            </Flex>

            <div className="welcome-buttons">
                <SkButton title={ continueLabel } onClick={ handleComplete } disabled={ needsMore } style={{ opacity: needsMore ? 0.6 : 1 }} />
                { needsMore ? <SkButton variant='text' onClick={ onNext }>Ask Me Later</SkButton> : null }
            </div>

        </Flex>
    )
})