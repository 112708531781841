import React, { useEffect, useMemo, useState } from "react";
import { BsArrowLeft, BsArrowRight, BsX } from "react-icons/bs";
import { Colors } from "../constants/Colors";
import { ToggleModel } from "../hooks/useToggle";
import { useWindowSize } from "../hooks/useWindowSize";
import { WindowEvents } from "../utils/WindowEvents";
import { Flex } from "./Flex";
import { SkCircleButton } from "./SkButton";
import { SkBackdrop } from "./SkModal";

import "./SkImageTheater.scss";

interface SkImageTheaterProps {
    toggle: ToggleModel,
    images: string[]
    start?: number
}

export const SkImageTheater = React.memo((props: SkImageTheaterProps) => {

    const { images, start, toggle } = props
    const [ idx, setIdx ] = useState(start || 0)

    // TODO -- Install swipe for mobile
    // const handleSwipe = (e: HammerInput) => {
    //     if(e.distance > (window.innerHeight / 30)) toggle.close()
    // }

    const go = (change: number) => {
        const newIdx = idx + change
        const effIdx = Math.max(0, Math.min((images?.length || 0) - 1, newIdx))
        setIdx(effIdx)
    }

    useEffect(() => {
        const sub = WindowEvents.popState.subscribe(() => { if (!window.location.hash.includes('#img')) toggle.close() })
        return () => WindowEvents.popState.unsubscribe(sub);
    }, [])

    useEffect(() => {
        setIdx(start || 0)
        if (toggle.isOpen) window.location.hash = '#img'
    }, [ start, toggle.isOpen ])

    const handleClose = () => window.history.back()

    if (!images?.length) return null

    return (
        <SkBackdrop open={ toggle.isOpen } onClick={ handleClose }>
            <div className="component-skImageTheater">
                <SkCircleButton htmlId='image-theater-close' variant='secondary' onClick={ handleClose }>
                    <BsX size={ 30 } />
                </SkCircleButton>
                
                <Flex row justify='center' align='center' style={{
                    width: '100%', height: '100%'
                }}>
                    <FullHeightMove direction='backward' onClick={ () => go(-1) } disabled={ idx <= 0 } />
                    <div style={{
                        flex: 1,
                        backgroundImage: `url(${ images[idx] })`, height: '100%', maxWidth: 1024, backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                        }} onClick={ e => e.stopPropagation() }>
                    </div>
                    <FullHeightMove direction='forward' onClick={ () => go(1) } disabled={ idx >= images.length - 1 } />
                </Flex>
            </div>
        </SkBackdrop>
    )

})

const FullHeightMove = React.memo((props: { direction: 'forward' | 'backward', disabled: boolean, onClick: () => void }) => {

    const { isMobile } = useWindowSize()
    const { direction, disabled, onClick } = props

    const { icon, posProp } = useMemo(() => {
        switch(direction) {
            case 'backward': return { icon: <BsArrowLeft color='white' size={ 30 } />, posProp: 'left' }
            case 'forward': return { icon: <BsArrowRight color='white' size={ 30 } />, posProp: 'right' }
        }
    }, [ direction ])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!disabled) onClick?.()
    }

    return (
        <Flex htmlId={`image-theater-${ direction }`} justify='center' align='center' style={{
            zIndex: 4,
            height: '100%',
            minWidth: isMobile ? '50vw' : '13vw',
            maxWidth: 200,
            position: 'absolute',
            [posProp]: 0,
            opacity: isMobile ? 0 : 1,
            backgroundColor: 'rgba(0,0,0,0.4)'
        }} onClick={ handleClick }>
            { disabled ? null : icon }
        </Flex>
    )

})