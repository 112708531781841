import React, { useEffect, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { SkCard } from "./Card";
import { Flex } from "./Flex";
import { SkButton, SkCircleButton } from "./SkButton";
import { SkText } from "./SkText";
import { BsX } from 'react-icons/bs'
import { UiConstants } from "../constants/UiConstants";
import { Colors } from "../constants/Colors";
import { useAsyncEffect } from "../hooks/useAsyncEffect";

import "./SkModal.scss";

interface SkModalProps {
    open: boolean;
    onClose: () => void;
    closeOnBackdropClick?: boolean;
    children: JSX.Element[] | JSX.Element;
    closerStyle?: 'button' | 'x' | 'none';
    noPadding?: boolean;
    title?: string;
    position?: 'center' | 'top' | 'bottom';
}

export const SkModal = React.memo((props: SkModalProps) => {

    const { open, onClose, children, closeOnBackdropClick = true, closerStyle = 'x', title, position = 'center' } = props;

    const handleDismiss = (e: React.MouseEvent<HTMLDivElement>) => {
        if (closeOnBackdropClick && e.target === e.currentTarget) 
            onClose();
    };

    return (
        <SkBackdrop open={open} onClick={handleDismiss}>
            <div className={`modal-dialog ${position}`}>
                { !title || closerStyle === 'none' ? null : (
                    <div className="modal-title">
                        <h3 className="dialog-title">{ title }</h3>
                        { closerStyle === 'x' ? <SkCircleButton variant="secondary" onClick={ onClose }><BsX color='white' size={ 30 } /></SkCircleButton> : null }
                    </div>
                )}
                {/* {
                    !title || !closerStyle || closerStyle === 'none' ? null : (
                        <Flex row align='center' justify='space-between' style={{ padding: noPadding ? UiConstants.standardSpacing : 0 }}>
                            { <h3 className="dialog-title">{ title }</h3> }
                            { closerStyle === 'x' ? <SkCircleButton variant="secondary" onClick={ onClose }><BsX color='white' size={ 30 } /></SkCircleButton> : null }
                        </Flex>
                    )
                } */}
                { children }
            </div>
        </SkBackdrop>
    );
});

export const SkModalActions = React.memo((props: { children: any }) => {
    const { children } = props;

    return (
        <div className="modal-actions">
            { children }
        </div>
    );
});

export const SkBackdrop = React.memo((props: { children: JSX.Element | JSX.Element[], onClick?: (e: React.MouseEvent<HTMLDivElement>) => void, open?: boolean }) => {

    const { open, onClick } = props;
    const [show, setShow] = useState(false);
    const [internalOpen, setInternalOpen] = useState(false);

    useEffect(() => {
        if(!show && open) {
            setShow(true);
            setTimeout(() => {
                setInternalOpen(true);
            }, 10);
        }
        else if(show && !open) {
            setInternalOpen(false);
            setTimeout(() => {
                setShow(false);
            }, 200);
        }
    }, [open, show]);

    if (!show) 
        return null;

    return (
        <Flex className="component-skModal">
            <div className={`dialog-overlay ${internalOpen ? 'open' : ''}`}></div>
            <div className={`dialog-container ${internalOpen ? 'open' : ''}`} onClick={onClick}>
                { props.children }
            </div>
        </Flex>
    );
})