import Axios from "axios"
import { ArtistProfile } from "../models/Artist"
import { SkResponse } from "../models/SkResponse"
import { MakeUrl } from "./Urls"

export const SearchAction = async (query: string): Promise<SkResponse<ArtistProfile[]>> => {
    return await Axios.get(MakeUrl('/search', { q: query }))
}

export const GetSearchKeyAction = async (): Promise<SkResponse<{key: string}>> => {
    return await Axios.get(MakeUrl('/algolia/key'))
}