import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { ShortArtistCell } from "../components/ShortArtistCell";
import { SkFilter, SkFilterSchema } from "../components/SkFilters";
import { SkText } from "../components/SkText";
import { SkRoutes } from "../constants/Routes";
import { GetFollowedArtists } from "../data/actions/ArtistActions";
import { useSortedArtists } from "../hooks/useSortedArtists";
import { QueryString } from "../utils/QueryString";

import "./FollowedScreen.scss";

export const FollowedScreen = React.memo(() => {

    const followed = useQuery([ 'followed-artists' ], GetFollowedArtists)
    const nav = useNavigate();

    const handleFilter = (filters: object) => { nav(SkRoutes.Following(filters)) }
    const filterRaw = QueryString.useParams();
    const filters = {
        tags: filterRaw.tags?.split(',').filter(x => !!x),
        status: filterRaw.status?.split(',').filter(x => !!x)
    }

    const filterSchema = useMemo(() => {

        const s: SkFilterSchema[] = [
            {
                id: 'status',
                label: 'Commission Status',
                options: [
                    { label: 'Open', value: 'open' },
                    { label: 'Opening Soon', value: 'opening-soon' }
                ],
                defaultLabel: 'Any Status'
            },
            {
                id: 'tags',
                label: 'Tags',
                options: Array.from(new Set(followed?.data?.data?.flatMap(a => a.tags)))
                    .map(x => ({ label: x, value: x })),
                defaultLabel: 'All Tags'
            }
        ]

        return s;

    }, [ followed ])

    const sorted = useSortedArtists(followed?.data?.data || [])

    const results = useMemo(() => {

        let r = sorted
        if (filters.status?.length) r = r.filter(a => (filters.status).some(s => s === a.commissionStatus))
        if (filters.tags?.length) r = r.filter(a => (filters.tags).some(tag => a.tags.includes(tag)))
        return r

    }, [ sorted, filters ])

    return (
        <ScreenWrapper className="followed-screen" padded>

            {/* <SkText variant='headline'>Following</SkText> */}
            <h1>Following</h1>

            <div>

            <SkFilter schemas={ filterSchema } value={ filters } onUpdate={ handleFilter } />

            {/* <hr /> */}

            <section className="follow-list">
                { results.map(a => <ShortArtistCell key={ a._id } artist={ a } button='subscribed' />) }
            </section>

            </div>

        </ScreenWrapper>
    )


})
