import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useNavRoute = () => {

    const l = useLocation()
    return useMemo(() => {
        if (!l.pathname.replace(/^\//, '')) return 'home'
        else if (l.pathname?.includes('search')) return 'search'
        else if (l.pathname?.includes('following')) return 'following'
        else if (l.pathname?.includes('explore')) return 'explore'
        return 'other'
    }, [ l.pathname ])

}