import { useQuery } from "@tanstack/react-query"
import React, { useMemo } from "react"
import { TbPaw } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { Colors } from "../constants/Colors"
import { UiConstants } from "../constants/UiConstants"
import { GetUndefined } from "../data/actions/ControlActions"
import { GetCurrentUserAction } from "../data/actions/UserActions"
import { QueryKeys } from "../data/QueryKeys"
import { useWindowSize } from "../hooks/useWindowSize"
import { Flex } from "./Flex"

import "./UserImage.scss";

// TODO: one day this will also be used to show pfps for other users:
//    -> Add userId prop
//    -> Component should render current user and nav to /profile if no userId is provided

interface UserImageProps {
    size?: number,
    navOnClick?: boolean
    onClick?: () => void
    userId?: string
    url?: string // This is here so that we don't end up making a zillion API calls if we already have the URL on hand. Also we don't have an API endpoint to get other user info anyway
}

export const UserImage = React.memo((props: UserImageProps) => {

    const { url, userId, size = UiConstants.inputHeight, navOnClick, onClick } = props
    const me = useQuery(userId ? QueryKeys.Nothing() : QueryKeys.User.Current.Profile(), userId ? GetUndefined : GetCurrentUserAction)
    const { scale } = useWindowSize()
    const s = size * scale
    const imageUrl = url || me.data?.data?.imageUrl;

    const nav = useNavigate()
    const handleClick = useMemo(() => {
        if (onClick) return onClick
        if (navOnClick) return () => nav('/profile')
        return undefined
    }, [ navOnClick ]);

    const classes = [
        'component-userImage',
        (onClick || navOnClick ? 'button' : '')
    ].join(' ');

    if (!imageUrl) return (
        <Flex className={classes} justify='center' align='center' onClick={ handleClick } style={{
            width: s, height: s,
            background: Colors.Gradient,
            cursor: handleClick ? 'pointer' : undefined,
            transform: 'rotate(30deg)'
        }}>
            <TbPaw color='white' size={ s * 0.5 } />
        </Flex>
    )



    return (
        <div className={classes} onClick={ handleClick } style={{
            width: s, height: s,
            backgroundColor: 'white',
            backgroundImage: `url(${ imageUrl }), var(--gradient-neutral)`,
            backgroundSize: 'cover',
            cursor: handleClick ? 'pointer' : undefined
            }}>
        </div>
    )

})