import React from "react";
import { useWindowSize } from "../hooks/useWindowSize";

export type SkTextVariant = 'headline' | 'section' | 'c1' | 'c2' | 'name' | 'descriptor' | 'control-label'

interface SkTextProps {
    href?: string
    variant: SkTextVariant
    faded?: boolean
    children?: any
    onClick?: () => void
    html?: string
    style?: React.CSSProperties
}

const sizes: Record<SkTextVariant, number> = {
    c1: 24,
    c2: 16,
    descriptor: 16,
    name: 16,
    headline: 32,
    section: 28,
    'control-label': 20
}

const BOLD = 700
const SEMIBOLD = 500
const NORMAL = 400
const weights: Record<SkTextVariant, number> = {
    c1: SEMIBOLD,
    c2: SEMIBOLD,
    descriptor: NORMAL,
    name: BOLD,
    headline: BOLD,
    section: BOLD,
    'control-label': BOLD
}

export const SkText = React.memo((props: SkTextProps) => {

    const { faded } = props;
    const v = props.variant || 'c1';

    // TODO -- handle dark/light theme
    const color = props.faded ? '#929294' : '#ffffff'
    const { scale } = useWindowSize()
    const html = props.html ?{ __html: props.html } : undefined

    const styles: React.CSSProperties = { 
        // fontSize: sizes[v] * scale, 
        // fontWeight: weights[v], 
        // color, 
        cursor: props.onClick ? 'pointer' : undefined, 
        // textDecoration: 'none', 
        ...props.style 
    };

    if (props.href) return (
        <a className={faded ? 'subtle' : ''} href={ props.href } style={ styles } target='_blank' dangerouslySetInnerHTML={ html }>
            { props.children }
        </a>
    )

    return (
        <span className={faded ? 'subtle' : ''} style={ styles } onClick={ props.onClick } dangerouslySetInnerHTML={ html }>
            { props.children }
        </span>
    )

})