
const groupByFirst = <TItem>(list: TItem[], keySelector: (item: TItem) => string): Record<string, TItem> => {
    return list.reduce((lookup, next) => {
        const key = keySelector(next);
        if (!lookup[key]) lookup[key] = next
        return lookup
    }, {})
}

const groupByAll = <TItem>(list: TItem[], keySelector: (item: TItem) => string): Record<string, TItem[]> => {
    return list.reduce((lookup, next) => {
        const key = keySelector(next);
        if (!lookup[key]) lookup[key] = []
        lookup[key].push(next)
        return lookup
    }, {})
}
 
export const GroupBy = {
    First: groupByFirst,
    All: groupByAll
}