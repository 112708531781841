import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ClerkProvider } from "@clerk/clerk-react";
import reportWebVitals from './reportWebVitals';
import { BaseScreen } from './screens/BaseScreen';
import { ReactQuery } from './utils/ReactQuery';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./index.scss";

const queryClient = ReactQuery.getClient();
const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const clerkAppearance = {
    variables: {
        fontFamily: 'var(--font-family)',
        fontSize: '1.2rem',
        spacingUnit: '1rem'
    }
}

root.render(
    <React.StrictMode>
        <ClerkProvider publishableKey={publishableKey} appearance={clerkAppearance}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <BaseScreen />
                </BrowserRouter>
            </QueryClientProvider>
        </ClerkProvider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Install keyboard shortcuts
document.addEventListener('keydown', e => {
    if (e.key === 'ArrowLeft') (document.querySelector('#image-theater-backward') || {} as any).click?.()
    if (e.key === 'ArrowRight') (document.querySelector('#image-theater-forward') || {} as any).click?.()
    if (e.key === 'Escape') (document.querySelector('#image-theater-close') || {} as any).click?.()
})

if('virtualKeyboard' in navigator) {
    (navigator.virtualKeyboard as any)['overlaysContent'] = true;
    // navigator.virtualKeyboard?.addEventListener('visibilitychange', () => {
    //     if(navigator.virtualKeyboard?.visible) {
    //         document.body.classList.add('virtual-keyboard-visible')
    //     }
    //     else {
    //         document.body.classList.remove('virtual-keyboard-visible')
    //     }
    // })
}