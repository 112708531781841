import React, { useCallback, useMemo, useState, useTransition } from "react";
import { Colors } from "../constants/Colors";
import { Flex } from "./Flex";
import { SkText } from "./SkText";
import { SkCard } from "./Card";
import { SkPill } from "./SkPill";

import "./SkFilters.scss";

export interface SkFilterSchema {
    id: string
    label: string
    defaultLabel: string
    options: { label: string, value: any }[]
}

interface SkFilterProps {
    schemas: SkFilterSchema[],
    value: object
    style?: React.CSSProperties
    onUpdate: (newValue: object) => void
}

export const SkFilter = React.memo((props: SkFilterProps) => {

    const { schemas, value, onUpdate, style } = props
    const [ selectedSchema, setSelectedSchema ] = useState<string>()

    const editor = useMemo(() => {

        const ss = schemas.find(s => s.id === selectedSchema)
        if (!ss) return null

        const valueSelected = (v: any) => {
            const existing = value[ss.id] as any[];
            return existing?.some?.(x => x === v) || false;
        }

        const selectValue = (v: any) => {
            const existing = value[ss.id]
            if (!existing || !Array.isArray(existing)) onUpdate({ ...value, [ ss.id ]: [v] }) // Add (first value)
            else if (existing?.some?.(x => x === v)) onUpdate({ ...value, [ ss.id ]: existing.filter(x => x !== v) }) // Remove
            else onUpdate({ ...value, [ ss.id ]: [ ...existing, v ] }) // Add
        }

        return (
            <SkCard className="skFilters-filter" style={{ width: '100%' }}>
                {/* <SkText variant='c1'>{ ss.label }</SkText> */}
                <h4>{ss.label}</h4>
                <hr />

                <Flex row style={{ marginTop: 20, maxWidth: '100%', flexWrap: 'wrap' }}>
                    {
                        ss.options.map(o => (
                            <SkPill label={ o.label } unselectedColor={ Colors.DarkBackground } onClick={ () => selectValue(o.value) } selected={ valueSelected(o.value) } />
                        ))
                    }
                </Flex>
            </SkCard>
        )

    }, [ selectedSchema, value, onUpdate ])

    const handleSelectSchema = (s: SkFilterSchema) => { setSelectedSchema(selectedSchema === s.id ? undefined : s.id) }

    const schemaLabel = useCallback((s: SkFilterSchema) => {
        const existing = value[s.id];
        if (!existing || !Array.isArray(existing) || !existing.length) 
            return s.defaultLabel;
        else
            return existing.map(e => {
                return s.options.find(o => o.value === e)?.label || undefined
            }).filter(x => !!x).join(', ')
    }, [ value ])

    return (
        <Flex className="component-skFilters" style={ style }>
            <div className="skFilters-schemaControls">
                {
                    schemas.map(s => (
                        <SkPill label={ schemaLabel(s) } selected={ selectedSchema === s.id } onClick={ () => handleSelectSchema(s) } useTriangle />
                    ))
                }
            </div>
            { editor }
        </Flex>
    )

})
