import React from "react"
import { Colors } from "../constants/Colors"
import { UiConstants } from "../constants/UiConstants"
import { Flex } from "./Flex"
import { SkText, SkTextVariant } from "./SkText"

import "./SkPill.scss";

interface SkPillProps {
    label: string, selected?: boolean, onClick?: () => void, useTriangle?: boolean, unselectedColor?: string,
    before?: JSX.Element, after?: JSX.Element
    style?: React.CSSProperties
    textVariant?: SkTextVariant
}

export const SkPill = React.memo((props: SkPillProps) => {

    const { label, selected, onClick, unselectedColor, textVariant, before, after, style } = props
    //const color = selected ? Colors.PrimaryBlue : (unselectedColor || Colors.DarkerBackground)

    return (
        <Flex className={`component-skPill ${selected ? 'selected': ''}`} style={{ cursor: onClick ? 'pointer' : undefined, ...style }} onClick={ onClick }>
            { before }
            <SkText style={{ color: 'white', whiteSpace: 'nowrap' }} variant={ textVariant || 'c2' }>{ label }</SkText>
            { after }
        </Flex>
    )
})