import { Requester as AlgoliaRequester, Response as AlgoliaResponse, Request } from '@algolia/requester-common';
import { MakePromise } from '../utils/MakePromise';

const ALGOLIA_KEYSTROKE_DEBOUNCE_TIMEOUT_MS = 400

let algoliaSearchTimeout: NodeJS.Timeout;

export const DebouncedAlgoliaRequester: AlgoliaRequester = {

    send: async (r: Request) => {

        const { promise, resolve, reject } = MakePromise<AlgoliaResponse>()

        const hasNoQuery = r.data ? /&query=&/.test(r.data) : true;
        const hasFilter = r.data ? /filter=[^&]/.test(r.data) : false;

        // We use filter requests to pupulate the browse screen, which we don't want to have the debounce
        if (hasFilter) {
            console.log('Permitting filtered search')
            const result = await fetch(r.url, {
                method: r.method,
                headers: r.headers as any,
                body: r.data as any
            })

            resolve({
                content: await result.text(),
                isTimedOut: false,
                status: result.status
            })
        }

        // Don't waste our quota by searching for nothing like the SDK wants to do :eyeroll:
        if (hasNoQuery) {
            resolve({ content: noResultsJson, isTimedOut: false, status: 200 })
            return promise
        }

        if (algoliaSearchTimeout) clearTimeout(algoliaSearchTimeout)

        algoliaSearchTimeout = setTimeout(async () => {
            try {

                // Set timeout for if the request takes too long
                let timedOut = false
                const maxTimeout = setTimeout(() => {
                    timedOut = true
                    console.log('>> [ALGOLIA] Timed out!')
                    resolve({
                        content: '',
                        isTimedOut: true,
                        status: 0
                    });
                }, r.responseTimeout)

                // Ask Algolia for data
                const result = await fetch(r.url, {
                    method: r.method,
                    headers: r.headers as any,
                    body: r.data as any
                })

                clearTimeout(maxTimeout)
                const resultText = await result.text()

                // Only emit results if it didn't take too long
                if (!timedOut) {
                    resolve({
                        content: resultText,
                        isTimedOut: false,
                        status: result.status
                    })
                } else {
                    console.log('>> [ALGOLIA] Timed out!')
                }

            } catch (e) {
                // Reject if something unexpected happened
                console.error(e)
                reject(e)
            }

        }, ALGOLIA_KEYSTROKE_DEBOUNCE_TIMEOUT_MS)

        return promise
    }


}

const noResultsJson = JSON.stringify({
    results: [
        {
            hits: [],
            nbHits: 0,
            page: 0,
            nbPages: 0,
            hitsPerPage: 20,
            exhaustiveNbHits: true,
            exhaustiveTypo: true,
            exhaustive: {
                nbHits: true,
                typo: true
            },
            query: "",
            params: "facets=%5B%5D&highlightPostTag=__%2Fais-highlight__&highlightPreTag=__ais-highlight__&query=&tagFilters=",
            index: "default",
            renderingContent: {},
            processingTimeMS: 1,
            processingTimingsMS: {
                _request: {
                    roundTrip: 103
                }
            },
            serverTimeMS: 1
        }
    ]
})