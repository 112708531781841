const htmlReplacements = {
    'amp': '&',
    'gt': '>',
    'lt': '<',
    'nbsp': '\u00A0',
}

export const FormatString = {

    DecodeHtmlEntitiesQuickNDirty: (src: string, maxReplacements: number = 20) => {

        let out = src

        // Get all entities in src text, without framing characters (e.g., "This &amp; That" => ['amp'])
        const tokens = Array.from(new Set(src.match(/&[\#a-z0-9]+;/g)?.map(e => e.slice(1, -1)) || [])).slice(0, maxReplacements)

        // Not the most efficient way to do this, but I'm not about to write a full on compiler to avoid the multiple .replace calls
        for(let t of tokens)
        {
            if (!t) continue
            if (htmlReplacements[t]) out = out.replace(`&${t};`, htmlReplacements[t])
            if (t[0] === '#') {
                const code = parseInt(t.slice(1))
                if (isNaN(code)) continue;
                out = out.replace(`&${t};`, String.fromCharCode(code))
            }
        }

        return out;
    }

}