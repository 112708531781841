import React, { useMemo, useRef } from "react";
import { LegacyRef } from "react";

import "./Flex.scss";

interface FlexProps {
    row?: boolean
    column?: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    justify?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center'
    align?: 'flex-start' | 'flex-end' | 'space-between' | 'center' | 'stretch'
    style?: React.CSSProperties
    children?: any
    htmlId?: string
    className?: string
    gap?: string;
}

export const Flex = React.memo((props: FlexProps) => {

    const { row, justify, align, style, children, onClick, htmlId, className, gap } = props

    const s: React.CSSProperties = useMemo(() => {
        return {
            flexDirection: row ? 'row' : undefined, //'column',
            justifyContent: justify || undefined, //'flex-start',
            alignItems: align || undefined, //'flex-start',
            gap: gap,
            ...style
        }
    }, [ props ])

    const classes = [
        'component-flex',
        className || ''
    ]

    return (
        <div className={classes.join(' ')} style={ s } id={ htmlId } onClick={ onClick }>{ children }</div>
    )

})