import { useState, useEffect, useCallback } from 'react';

interface UseDragScrollOptions {
    on: boolean
    sliderRef: any
    momentumVelocity: number
}

// Credit: adapted from https://www.npmjs.com/package/use-drag-scroll
export const useDragScroll = (options: UseDragScrollOptions, deps?: any[]) => {

    const { on, sliderRef, momentumVelocity } = options

    // TODO -- is there a way we can make this not a state member, so that the consuming component
    // doesn't render every time it changes?
    const [hasSwiped, setHasSwiped] = useState(false)

    const init = useCallback(() => {

        if (!on) return;

        const slider = sliderRef.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            cancelMomentumTracking();
        });

        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
            beginMomentumTracking();
            setTimeout(() => setHasSwiped(false), 0)
        });

        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1;
            let prevScrollLeft = slider.scrollLeft;
            slider.scrollLeft = scrollLeft - walk;
            velX = slider.scrollLeft - prevScrollLeft;
            if (slider.scrollLeft - prevScrollLeft && !hasSwiped) {
                setHasSwiped(true)
            }
        });

        // Momentum 
        let velX = 0;
        let momentumID;

        slider.addEventListener('wheel', (e) => {
            cancelMomentumTracking();
        });

        const beginMomentumTracking = () => {
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }

        const cancelMomentumTracking = () =>{
            cancelAnimationFrame(momentumID);
        }

        const momentumLoop = () => {
            slider.scrollLeft += velX;
            velX *= momentumVelocity;
            if (Math.abs(velX) > 0.5) {
                momentumID = requestAnimationFrame(momentumLoop);
            }
        }

    }, [on, sliderRef, momentumVelocity])

    useEffect(() => {
        init()
    }, [...(deps || []), on])

    return { hasSwiped }

}