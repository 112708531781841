import axios from "axios"
import { Report } from "../models/Report"
import { SkResponse } from "../models/SkResponse"
import { MakeUrl } from "./Urls"

export const GetOpenReports = async (): Promise<SkResponse<Report[]>> => {
    return axios.get(MakeUrl('/reports/open'))
}

export const ApproveReport = async (reportId: string): Promise<SkResponse<Report[]>> => {
    return axios.put(MakeUrl(`/reports/${reportId}/approve`))
}

export const DenyReport = async (reportId: string): Promise<SkResponse<Report[]>> => {
    return axios.put(MakeUrl(`/reports/${reportId}/deny`))
}

export const SendArtistStatusReportAction = async (data: {
    artistHandle: string,
    correctedStatus: string,
    tweetUrl: string,
}) => {
    return await axios.post(MakeUrl('/reports/artist-status'), data)
}