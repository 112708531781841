import React, { useMemo } from "react";
import { Flex } from "../../components/Flex";
import { SkText } from "../../components/SkText";
import { CommissionColor, CommissionGradient } from "../../constants/Colors";
import { UiConstants } from "../../constants/UiConstants";
import { ArtistProfile } from "../../data/models/Artist";
import { useWindowSize } from "../../hooks/useWindowSize";
import { FormatDate } from "../../utils/FormatDate";

export const CommissionStatusBanner = React.memo((props: { artist: ArtistProfile }) => {

    const { artist } = props
    const { isMobile } = useWindowSize()

    const text = useMemo(() => {
        switch (artist?.commissionStatus) { 
            case 'closed': return 'Commissions are Closed';
            case 'open': return 'Open for Commissions';
            case 'opening-soon': return 'Opening for Commissions Soon';
            default: return 'Commission Status Unknown'
        }
    }, [ artist?.commissionStatus ])

    return (
        <Flex style={{
            borderRadius: UiConstants.borderRadius,
            width: '100%', padding: 20,
            background: CommissionGradient(artist?.commissionStatus),
            boxSizing: 'border-box'
        }} justify='space-between'>
            <SkText variant='c1'>{ text }</SkText>
            {/* { artist?.commissionStatusDate ? <SkText variant='control-label' style={{ color: 'rgba(0,0,0,0.5)' }}>{ FormatDate.dateOnly(artist?.commissionStatusDate) }</SkText> : null } */}
            { artist?.commissionStatusNote ? <SkText variant='c2'>{ artist?.commissionStatusNote }</SkText> : null }
        </Flex>
    )

})