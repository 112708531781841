import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../constants/Colors";
import { useWindowSize } from "../hooks/useWindowSize";

export const Logo = React.memo((props: { size?: number, gradient?: boolean, kerning?: number, variant?: 'word' | 'letter' }) => {

    const { size = 20, gradient, kerning, variant } = props
    const nav = useNavigate()
    const { isMobile } = useWindowSize();
    const v = useMemo(() => {
        if (variant) return variant
        return isMobile ? 'letter' : 'word'
    }, [ isMobile, variant ])

    return (
        <div style={{
            letterSpacing: kerning || -2,
            // padding: '0 20px',
            fontSize: size, fontWeight: 700,
            color: gradient ? 'transparent' : 'white',
            cursor: 'pointer',
            background: gradient ? Colors.Gradient : undefined,
            backgroundClip: gradient ? 'text' : undefined,
            WebkitBackgroundClip: gradient ? 'text' : undefined,

            display: 'flex', alignItems: 'center', justifyContent: 'center'

        }} onClick={ () => nav('/') }>
            { v === 'word' ? 'SKRUNKLE' : 'S' }
        </div>
    )

})