import { create } from "zustand";
import { ArtistProfile, ArtistSearchResult } from "../../data/models/Artist";
import { FeedItem } from "../../data/models/FeedItem";
import { SearchArtists } from "../../data/actions/ArtistActions";
import { OpenArtistsFeedAction, OpeningSoonArtistsFeedAction, SearchPostsAction } from "../../data/actions/FeedActions";
import { CreateAlgoliaFilter } from "../../components/Search/ConfigureSearchForTypes";
import { AlgoliaRecord } from "../../data/models/AlgoliaRecord";
import { useConfig } from "../../stores/ConfigStore";
import { GroupBy } from "../../utils/GroupBy";
import { QueryString } from "../../utils/QueryString";
import { Navigate, NavigateFunction } from "react-router-dom";
import { SkRoutes } from "../../constants/Routes";
import { match } from "ts-pattern";

export type BrowseScreenSearchContext = {

    artistResults: ArtistSearchResult[] | undefined
    postResults: FeedItem[] | undefined

    setResultsUndefined: () => void
    setResultsEmpty: () => void
    searchUsingApi: (statuses: string[], tags: string[]) => Promise<void>
    searchUsingAlgolia: (statuses: string[], tags: string[], query: string) => Promise<void>

    clearQueryFromRoute: (nav: NavigateFunction) => void

}

export const useBrowseScreenSearchContext = create<BrowseScreenSearchContext>((set, get) => {

    return {

        artistResults: [],
        postResults: [],

        setResultsUndefined: () => set({ artistResults: undefined, postResults: undefined }),
        setResultsEmpty: () => set({ artistResults: [], postResults: [] }),

        searchUsingApi: async (statuses: string[], tags: string[]): Promise<void> => {

            const loadArtists = async () => {
                const artists = await SearchArtists({ statuses, tags })
                set({ artistResults: artists?.data || []})
            }

            const loadPosts = async () => {
                //@ts-ignore
                const postsAction = match(statuses)
                    .when(l => l.includes('open'), () => () => OpenArtistsFeedAction(tags))
                    .when(l => l.includes('opening-soon'), () => () => OpeningSoonArtistsFeedAction(tags))
                    .otherwise(() => () => SearchPostsAction({ tags }))
                const posts = (await postsAction())?.data || [] 
                set({ postResults: posts })
                return posts
            }

            loadArtists()
            const p = await loadPosts()

            // TODO -- Get missing artists similar to what searchUsingAlgolia does


        },

        searchUsingAlgolia: async (statuses: string[], tags: string[], query: string): Promise<void> => {

            const loadArtists = async () => {
                console.log('Loading artists from Algolia')
                try {
                    const searchClient = useConfig.getState().algolia.searchClient;

                    if(searchClient) {
                        const artistsResponse = await searchClient.initIndex(useConfig.getState().algolia.indexName)
                            .search<AlgoliaRecord>(query, { filters: CreateAlgoliaFilter({ tags, commissionStatuses: statuses, types: ['artist'] }), hitsPerPage: 8 });
                        console.log('Loaded artists from Algolia')
                        console.log(artistsResponse)
                        set({ artistResults: artistsResponse.hits || [] })
                    }

                } catch (e) {
                    set({ artistResults: [] })
                }
            }

            const loadPosts = async () => {

                const toFeedItem = (p: AlgoliaRecord, artist: ArtistProfile): FeedItem => ({
                    artist,
                    artistHandle: p.handle,
                    commissionStatus: p.commissionStatus,
                    message: p.name,
                    timestamp: new Date(p.timestamp),
                    id: p.objectID,
                    media: p.postMediaUrls,
                    title: '',
                    type: 'general'
                })

                const searchClient = useConfig.getState().algolia.searchClient;
                if(searchClient) {
                    // Report posts asap, get artists in a sec
                    const postsResponse = await searchClient.initIndex(useConfig.getState().algolia.indexName)
                        .search<AlgoliaRecord>(query, { filters: CreateAlgoliaFilter({ tags, commissionStatuses: statuses, types: ['post'] }), hitsPerPage: 20 });
                    const posts = postsResponse.hits
                    //set({ postResults: posts.map(p => toFeedItem(p)) })
                    console.warn('NOT IMPLEMENTED: Loading artists for Algolia posts')

                    const postHandles = Array.from(new Set(posts.map(p => p.handle)))
                    let postArtistLookup: { [handle: string]: ArtistProfile } = {}
                    if (postHandles.length) {
                        const postArtists = (await SearchArtists({ handles: postHandles }))?.data || []
                        console.log(`---------- post artists got[${postArtists.length}]`)
                        postArtistLookup = GroupBy.First(postArtists, a => a.handle)
                    }

                    // Re-emit with artist info once loaded
                    set({ postResults: posts.map(p => toFeedItem(p, postArtistLookup[p.handle])) })
                }
            }

            // Algolia doesn't allow concurrent searching so we have to do them in sequence
            await loadPosts()
            loadArtists()

        },
        
        clearQueryFromRoute: (nav) => {
            console.log('>>> Clearing query')
            const current = QueryString.GetParams()
            console.log({ currentBefore: current })
            if (current.q) delete current.q
            console.log({ currentAfter: current })
            //nav(SkRoutes.Browse(current))
        }

    }


})