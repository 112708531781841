import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FollowArtist, GetFollowedArtistHandles, GetMutedArtistHandles, GetSubscribedArtistHandles, MuteArtist, SubscribeToArtist, UnfollowArtist, UnmuteArtist, UnsubscribeFromArtist } from "../data/actions/ArtistActions"
import { SkResponse } from "../data/models/SkResponse"
import { QueryKeys } from "../data/QueryKeys"

export type ArtistIsType = 'followed' | 'subscribed' | 'muted'

// Convert an API string[] getter into an API Set<string> getter
const currySettify = (getter: () => Promise<SkResponse<string[]>>, state: ArtistIsType): (() => Promise<Set<string>>) => {
    return async () => {
        try {
            const result = await getter()
            if (!Array.isArray(result.data)) {
                console.log(`Error: API result for ${state} was not a list of handles!`)
                return new Set()
            }
            return new Set(result.data)
        } catch (e) {
            console.log(`Failed to fetch ${state} list:`, e)
            return new Set()
        }
    }
}

export const useArtistIs = (state: ArtistIsType, handle: string): { onList: boolean | undefined, saving: boolean, add: () => Promise<any>, remove: () => Promise<any> } => {

    // Maintain a "shadow" value that changes immediately when the user asks to add or remove the artist
    // so that the UI will immediately update.
    const [ initialized, setInitialized ] = useState(false)
    const [ shadowValue, setShadowValue ] = useState<boolean | undefined>(undefined)
    const [ saving, setSaving ] = useState(false)

    const { queryKey, action, add, remove } = useMemo(() => {

        switch (state) {
            case 'muted': return { queryKey: QueryKeys.User.Current.MutedArtistHandles(), action: currySettify(GetMutedArtistHandles, state), add: MuteArtist, remove: UnmuteArtist }
            case 'subscribed': return { queryKey: QueryKeys.User.Current.SubscribedArtistHandles(), action: currySettify(GetSubscribedArtistHandles, state), add: SubscribeToArtist, remove: UnsubscribeFromArtist }
            case 'followed':
            default: return { queryKey: QueryKeys.User.Current.FollowedArtists(), action: currySettify(GetFollowedArtistHandles, state), add: FollowArtist, remove: UnfollowArtist }
        }

    }, [state])

    // Allow data layer to re-set value if the handle changes
    useEffect(() => { setInitialized(false) }, [ handle ])

    const set = useQuery(queryKey, action)
    useEffect(() => {
        if (initialized) return; // Only let the data layer set the state if it hasn't told us yet
        const loadedValue = set.data?.has?.(handle)
        if (loadedValue !== undefined) {
            setShadowValue(loadedValue)
            setInitialized(true)
        }
    }, [ set, handle ])

    const handleUpdate = useCallback(async (desiredValue: boolean) => {
        if (!handle) return
        setSaving(true)
        setShadowValue(desiredValue)
        const action = (desiredValue === true) ? add : remove
        try { await action(handle) }
        catch (e) {
            if (e.response?.data?.code === 'INVALID_STATE') {} // If the API says we have the wrong opinion about this artist being on the list, believe them
            else setShadowValue(!desiredValue) // Otherwise, flip the state back to what it was before
        }
        setSaving(false)
    }, [ handle, add, remove, setShadowValue ])

    return { onList: shadowValue, saving, add: () => handleUpdate(true), remove: () => handleUpdate(false) }

}