import { memo } from "react";
import { MobileSearchArea } from "../components/Search/MobileSearchArea";
import { useHeaderSearchUiContext } from "../components/Search/useSearchUiContext";
import { ScreenWrapper } from "../components/ScreenWrapper";

import "./MobileSearchScreen.scss";

export const MobileSearchScreen = memo(() => {
    const headerSearch = useHeaderSearchUiContext();

    return (
        <ScreenWrapper className="search-screen">
            <MobileSearchArea onDisplayingResults={ headerSearch.setResultVisibility } />
        </ScreenWrapper>
    );
});