import React, { useEffect, useMemo, useState } from "react";
import { Colors } from "../../constants/Colors";
import { GetTelegramPairingCodeAction } from "../../data/actions/NotificationActions";
import { GetCurrentUserAction } from "../../data/actions/UserActions";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { Flex } from "../Flex";
import { SkButton } from "../SkButton";
import { SkText } from "../SkText";
import { NotificationSetupSuccessPage } from "./NotificationSetupSuccessPage";

let patience = 20
export const TelegramSetupPage = React.memo((props: { onDone: () => void }) => {

    const { onDone } = props
    const [ copied, setCopied ] = useState(false)
    const [ checking, setChecking ] = useState(false)
    const [ sleeping, setSleeping ] = useState(false)
    const [ setUp, setSetUp ] = useState(false)

    const [ code, setCode ] = useState<string>('');
    useAsyncEffect(async () => {
        const code = await GetTelegramPairingCodeAction()
        setCode(code?.data?.code)
    }, [])
    useEffect(() => setCopied(false), [ code ])

    const handlePressCode = () => {
        navigator.clipboard.writeText(code)
        setCopied(true)
    }

    const handleWakeUp = () => {
        patience = 20
        setSleeping(false)
    }

    useEffect(() => {
        const i = setInterval(async () => {
            if (patience-- <= 0) {
                setSleeping(true)
                return;
            }
            setChecking(true)
            setTimeout(() => setChecking(false), 1000)
            const user = await GetCurrentUserAction()
            if (user.data?.notifications?.telegram?.status === 'active') setSetUp(true)
        }, 4000)
        return () => clearInterval(i)
    }, [])

    const bonusText = useMemo(() => {
        if (checking) return 'Sniffin...'
        if (copied) return 'Copied!'
        else return 'Click to Copy'
    }, [ copied, code, checking ])


    const bottomContent = useMemo(() => {

        if (sleeping) return (
            <Flex align='center' style={{ width: '100%' }}>
                <SkText variant='c1' faded style={{ textAlign: 'center' }}>Just checking, are you still there?</SkText>
                <SkButton key='wake-up' onClick={ handleWakeUp } title='Yep!' style={{ marginTop: 40 }} />
            </Flex>
        )

        return (
            <Flex align='center' style={{ width: '100%' }}>
                <SkText variant='c1' faded style={{ textAlign: 'center' }}>Send the above code to us <strong>@SkrunkleBot</strong> on Telegram we can link your Telegram account.</SkText>
                <SkButton href='https://t.me/SkrunkleBot' title='Open Telegram' style={{ marginTop: 40 }} openInNewTab />
            </Flex>
        )

    }, [ sleeping ])

    if (setUp) return <NotificationSetupSuccessPage method='telegram' onDone={ onDone } />

    return (
        <Flex className="telegram-notifications" style={{ flex: 1, width: '100%' }}>

            <Flex justify='center' align='center' style={{ flex: 1, width: '100%', opacity: sleeping ? 0.2 : 1 }}>
                <SkText variant='headline' onClick={ handlePressCode } style={{ letterSpacing: 10, fontFamily: 'monospace', fontSize: 100, color: code ? Colors.PrimaryBlue : Colors.InactiveGray }}>{ code || '----' }</SkText>
                <SkText variant='control-label' style={{ marginTop: 10, opacity: code ? 0.5 : 0 }} faded>{ bonusText }</SkText>
            </Flex>

            { bottomContent }
        </Flex>
    )

})