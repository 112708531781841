import Axios from 'axios'
import { ArtistProfile } from '../models/Artist'
import { SkResponse } from '../models/SkResponse'
import { ArtistSearchParams } from '../requests/ArtistSearchParams'
import { UpdateArtistRequest } from '../requests/UpdateArtistRequest'
import { MakeUrl } from './Urls'

export const FollowArtist = async (artistHandle: string): Promise<SkResponse<void>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/follow`), null) }
export const UnfollowArtist = async (artistHandle: string): Promise<SkResponse<void>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/unfollow`), null) }
export const GetFollowedArtists = async (): Promise<SkResponse<ArtistProfile[]>> => { return await Axios.get(MakeUrl('/artists/followed')) }
export const GetFollowedArtistHandles = async (): Promise<SkResponse<string[]>> => { return await Axios.get(MakeUrl('/artists/followed/handles')) }

export const SubscribeToArtist = async (artistHandle: string): Promise<SkResponse<void>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/subscribe`), null) }
export const UnsubscribeFromArtist = async (artistHandle: string): Promise<SkResponse<void>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/unsubscribe`), null) }
export const GetSubscribedArtists = async (): Promise<SkResponse<ArtistProfile[]>> => { return await Axios.get(MakeUrl('/artists/subscribed')) }
export const GetSubscribedArtistHandles = async (): Promise<SkResponse<string[]>> => { return await Axios.get(MakeUrl('/artists/subscribed/handles')) }

export const MuteArtist = async (artistHandle: string): Promise<SkResponse<boolean>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/mute`), null) }
export const UnmuteArtist = async (artistHandle: string): Promise<SkResponse<boolean>> => { return await Axios.put(MakeUrl(`/artists/${ artistHandle }/unmute`), null) }
export const GetMutedArtistHandles = async (): Promise<SkResponse<string[]>> => { return await Axios.get(MakeUrl('/artists/muted/handles')) }

export const SearchArtists = async (params: ArtistSearchParams): Promise<SkResponse<ArtistProfile[]>> => await Axios.get(MakeUrl('/artists/search', params))

export const GetIntroArtists = async (): Promise<SkResponse<ArtistProfile[]>> => await Axios.get(MakeUrl('/artists/intro'))

export const GetArtistById = async (artistId: string): Promise<SkResponse<ArtistProfile>> => {
    return await Axios.get(MakeUrl(`/artists/${artistId}/profile`))
}

export const CreateArtistAction = async (bskyHandle: string): Promise<SkResponse<ArtistProfile>> => {
    return await Axios.post(MakeUrl(`/artists/create`), { bskyHandle })
}

export const UpdateArtistAction = async (update: Partial<UpdateArtistRequest>): Promise<SkResponse<ArtistProfile>> => {
    return await Axios.put(MakeUrl(`/artists/${update.twitterHandler}/save`), update)
}

export const DeleteArtistAction = async (artistId: string): Promise<SkResponse<ArtistProfile>> => {
    return await Axios.delete(MakeUrl(`/artists/${artistId}/delete`))
}
