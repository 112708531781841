import React, { useEffect, useMemo } from "react";
import { Flex } from "../components/Flex";
import { Logo } from "../components/Logo";
import { SkText } from "../components/SkText";
import { Colors } from "../constants/Colors";
import { useAuth } from "../hooks/useAuth";

export const SplashScreen = React.memo(() => {

    const auth = useAuth()
    const funText = useMemo(() => {
        switch (auth.state) {
            case 'needs-login': return '⚡ LOGIN TIME 💻';
            case 'ok': return 'Here we go!!'
            case 'not-ready':
            default: return 'One Moment Please 😎'
        }
    }, [ auth?.state ])

    useEffect(() => {
        if(auth.state === 'ok') {
            auth.getToken().then(token => { console.log(token) });
        }
        else if(auth.state === 'needs-login') {
            auth.redirectToLogin();
        }
    }, [auth?.state])

    return (
        <Flex align='center' justify='center' style={{ height: '100vh', width: '100vw', background: Colors.Gradient }}>
            <Logo size={ 96 } />
            <div style={{ transform: 'rotate(-5deg)', opacity: 0.2, marginTop: -100 }}>
                <Logo size={ 96 } />
            </div>
            <SkText variant='c2'>{ funText }</SkText>
        </Flex>
    )

})