import React from "react";
import { useWindowSize } from "../hooks/useWindowSize";

export const Bump = React.memo((props: { w?: number, h?: number, mobileOnly?: boolean }) => {

    const { isMobile } = useWindowSize()
    const { w, h, mobileOnly } = props

    if (mobileOnly && !isMobile) return null

    return (
        <div className="bump" style={{ minWidth: w || 1, minHeight: h || 1}}></div>
    )

})