import React, { MutableRefObject, Ref, useRef, useState } from "react";
import { UiConstants } from "../constants/UiConstants";
import { useWindowSize } from "../hooks/useWindowSize";
import { Flex } from "./Flex";
import { MdClose } from "react-icons/md";

import "./SkTextInput.scss";

interface SkTextInputProps {
    className?: string
    style?: React.CSSProperties
    placeholder?: string,
    onTextChange?: (value: string) => void
    onSubmit?: (value: string) => void
    onFocus?: () => void
    onBlur?: () => void
    onClick?: () => void
    onKey?: (key: string) => void
    showReset?: boolean
    onReset?: () => void
    noAutocomplete?: boolean
    noAutocorrect?: boolean
    noCapitalize?: boolean
    value?: string
    inputRef?: Ref<HTMLInputElement>
}

export const SkTextInput = React.memo((props: SkTextInputProps) => {

    const {
        className, style, placeholder, onTextChange, value, onSubmit, onFocus, onBlur, onClick, onKey,
        noAutocomplete, noAutocorrect, noCapitalize, onReset, showReset, inputRef
    } = props;
    const [_value, _setValue] = useState(value || '');

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        _setValue(e.target.value)
        onTextChange?.(e.target.value)
    }

    return (
        <div className="component-skTextInput">
            <input type="text"
                ref={inputRef}
                onChange={handleTextChange}
                value={value}
                autoComplete={noAutocomplete ? 'off' : undefined}
                autoCorrect={noAutocorrect ? 'off' : undefined}
                autoCapitalize={noCapitalize ? 'off' : undefined}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                onKeyDown={e => {
                    if (e.key === 'Enter') 
                        onSubmit?.(_value)
                    onKey?.(e.key)
                }}
                style={{
                    // color: 'white', fontSize: 23, fontWeight: 700, flex: 1, width: '100%',
                    // background: 'transparent', border: 0, outline: 0
                }} placeholder={placeholder} />
            {
                showReset && value &&
                <div className="input-reset">
                    <MdClose
                        color='white'
                        size={24}
                        onClick={() => {
                            if (!showReset) return;
                            onTextChange?.('')
                            onReset?.()
                        }}
                        // style={{ visibility: showReset && value ? 'visible' : 'hidden' }}
                    />
                </div>
            }
        </div>
    );

    // return (
    //     <Flex className={className} row style={{
    //         borderWidth: 0,
    //         width: '100%',
    //         maxWidth: '100%',
    //         boxSizing: 'content-box',
    //         borderRadius: UiConstants.borderRadius,
    //         padding: '0 30px',
    //         backgroundColor: '#121215',
    //         height: UiConstants.inputHeight * scale, ...style
    //     }} align='center'>
    //         <input type="text"
    //             ref={ref}
    //             onChange={handleTextChange}
    //             value={value}
    //             autoComplete={noAutocomplete ? 'off' : undefined}
    //             autoCorrect={noAutocorrect ? 'off' : undefined}
    //             autoCapitalize={noCapitalize ? 'off' : undefined}
    //             onFocus={onFocus}
    //             onBlur={onBlur}
    //             onClick={onClick}
    //             onKeyDown={e => {
    //                 if (e.key === 'Enter') onSubmit?.(_value)
    //                 onKey?.(e.key)
    //             }}
    //             style={{
    //                 color: 'white', fontSize: 23, fontWeight: 700, flex: 1, width: '100%',
    //                 background: 'transparent', border: 0, outline: 0
    //             }} placeholder={placeholder} />
    //         <MdClose
    //             color='white'
    //             size={24}
    //             onClick={() => {
    //                 if (!showReset) return;
    //                 onTextChange('')
    //                 onReset?.()
    //             }}
    //             style={{ visibility: showReset && value ? 'visible' : 'hidden' }}
    //         />
    //     </Flex>
    // )

})