import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const QueryString = {

    useParams: <TQuery = any>(): TQuery => {
        const l = useLocation()
        return useMemo(() => {
            const params = new URLSearchParams(l.search)
            return Array.from(params.entries())
                .reduce((lookup, next) => ({ ...lookup, [next[0]]: next[1] }), {}) as TQuery
        }, [l.search])
    },

    GetParams: <TQuery = Record<string, any>>() => {
        const params = new URLSearchParams(window.location.search)
        return Array.from(params.entries())
            .reduce((lookup, next) => ({ ...lookup, [next[0]]: next[1] }), {}) as TQuery
    },

    Stringify: (obj: object) => {
        const pairs = Object.entries(obj)
            .filter(([ key, value ]) => {
                if (Array.isArray(value)) return value.length > 0
                return !!value
            })
            .map(([ key, value ]) => `${key}=${ encodeURIComponent(Array.isArray(value) ? value.join(',') : value) }`)
        return `?${ pairs.join('&') }`
    },

    Object: {
        Pack: (obj: object) => obj ? encodeURIComponent(btoa(JSON.stringify(obj))) : '',
        Unpack: <TObject = any>(str: string) => str ? JSON.parse(atob(decodeURIComponent(str))) as TObject : null
    }

}