import { useQuery } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { BsGlobe, BsPencil } from "react-icons/bs";
import { CgShare } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { ArtistPlanet } from "../../components/ArtistPlanet";
import { Bump } from "../../components/Bump";
import { SkCard } from "../../components/Card";
import { Feed } from "../../components/Feed";
import { Flex } from "../../components/Flex";
import { ArtistStatusCorrectionModal } from "../../components/Reports/ArtistStatusCorrectionModal";
import { ScreenWrapper } from "../../components/ScreenWrapper";
import { ShortArtistCell } from "../../components/ShortArtistCell";
import { SkButton } from "../../components/SkButton";
import { SkImageTheater } from "../../components/SkImageTheater";
import { SkModal } from "../../components/SkModal";
import { SkText } from "../../components/SkText";
import { TagCloud } from "../../components/TagCloud";
import { Colors, CommissionGradient } from "../../constants/Colors";
import { SkRoutes } from "../../constants/Routes";
import { UiConstants } from "../../constants/UiConstants";
import { GetArtistById, SearchArtists } from "../../data/actions/ArtistActions";
import { GetEmptyList, GetUndefined } from "../../data/actions/ControlActions";
import { ArtistProfile } from "../../data/models/Artist";
import { QueryKeys } from "../../data/QueryKeys";
import { useArtistSearchContext } from "../../hooks/useArtistSearchContext";
import { useSelectedFeedItem } from "../../hooks/useSelectedFeedItem";
import { ToggleModel, useToggle } from "../../hooks/useToggle";
import { Share } from "../../utils/Share";
import { ArtistButtonsAndTags } from "./ArtistButtonsAndTags";
import { ArtistHeader } from "./ArtistHeader";
import { ArtistMuteButton } from "./ArtistMuteButton";
import { CommissionStatusBanner } from "./CommissionStatusBanner";
import { FaXTwitter } from "react-icons/fa6";

import "./ArtistScreen.scss";

export const ArtistScreen = React.memo(() => {

    const { artistId } = useParams<{ artistId: string }>();
    const q = useQuery(QueryKeys.Artist.Profile(artistId || ''), () => artistId ? GetArtistById(artistId) : GetUndefined(), { enabled: !!artistId });
    const feedItem = useSelectedFeedItem()

    const actionSheet = useToggle(false)
    const statusReport = useToggle(false)

    if (!artistId || !q.data?.data) 
        return null

    const artist: ArtistProfile = q.data?.data;

    return (
        <ScreenWrapper className="artist-screen">

            {/* Header */}
            <section className="artist-header">
                <ArtistHeader artist={ artist } />
                <Bump h={ 15 } />
                <ArtistButtonsAndTags artist={ artist } onMore={ actionSheet.open } />
                <Bump h={ 15 } />
                <CommissionStatusBanner artist={ artist } />
                <Bump h={ 15 } />
                <TagCloud tags={ artist?.tags } artist={ artist } side='left' showEditForAdmins />
            </section>

            <Bump h={ 20 } />
            <Feed searchParams={{ artistHandles: [ artistId ] }} onItemMediaClick={ feedItem.open } />

            { feedItem.context?.item?.media && 
                <SkImageTheater images={ feedItem.context.item.media } toggle={ feedItem.toggle } start={ feedItem.context?.selectedIndex } />
            }

            <ArtistStatusCorrectionModal artist={ artist } toggle={ statusReport } />
            <ArtistOverflowModal artist={ artist } toggle={ actionSheet } onOpenStatusReport={ statusReport.open } />

        </ScreenWrapper>
    )

})

const ArtistOverflowModal = React.memo((props: { artist: ArtistProfile, toggle: ToggleModel, onOpenStatusReport: () => void }) => {

    const { artist, toggle, onOpenStatusReport } = props;

    const openUrl = useCallback((url: string | undefined) => {
        if(url)
            window.open(url);
        return;
    }, []);

    return (
        <SkModal open={ toggle.isOpen } onClose={ toggle.close } closerStyle='x'>
            <ShortArtistCell artist={ artist } />
            <SkCard onClick={ () => { toggle.close(); onOpenStatusReport(); } } style={{ background: CommissionGradient(artist?.commissionStatus) }}>
                <Flex row>
                    <BsPencil size={ UiConstants.iconButtonSize } color='white' />
                    <Bump w={ UiConstants.standardSpacing } />
                    <Flex>
                        <SkText variant='name'>Is this artist's status wrong?</SkText>
                        <SkText variant='c2'>Suggest Status Change</SkText>
                    </Flex>
                </Flex>
            </SkCard>
            <Bump h={ UiConstants.tightSpacing } />
            <Flex row>
                <SkCard onClick={() => openUrl(artist?.twitter)} style={{ flex: 1 }}>
                    <Flex row>
                        <FaXTwitter size={ UiConstants.iconButtonSize } color='white' />
                        <Bump w={ UiConstants.standardSpacing } />
                        <Flex>
                            <SkText variant='name'>{ artist?.commissionInfoUrl ? 'Visit X Profile' : 'No X Profile' }</SkText>
                            <SkText variant='c2' faded>{ artist?.twitter ? `@${artist?.twitter}` : '---' }</SkText>
                        </Flex>
                    </Flex>
                </SkCard>
                <Bump w={ UiConstants.tightSpacing } />
                <SkCard onClick={() => openUrl(artist?.commissionInfoUrl)} style={{ flex: 1 }}>
                    <Flex row>
                        <BsGlobe size={ UiConstants.iconButtonSize } color='white' />
                        <Bump w={ UiConstants.standardSpacing } />
                        <Flex>
                            <SkText variant='name'>{ artist?.commissionInfoUrl ? 'Visit Website' : 'No Website Available' }</SkText>
                            <SkText variant='c2' faded>{ artist?.commissionInfoUrl || '---' }</SkText>
                        </Flex>
                    </Flex>
                </SkCard>
            </Flex>
            <Bump h={ UiConstants.tightSpacing } />
            <SkButton title='Share' variant='secondary-invert' icon={ <CgShare size={ UiConstants.iconButtonSize } color='white' /> } onClick={ () => Share.Artist(artist) } />
            <Bump h={ UiConstants.tightSpacing } />
            <ArtistMuteButton artistHandle={artist.handle} />
            <Bump h={ UiConstants.standardSpacing * 2 } />
            <SkButton variant='secondary' title='Close' onClick={ toggle.close } />
        </SkModal>
    )

})

// List of artist on the list where you picked this artist from... possible future feature
const ArtistContextList = React.memo(() => {

    const searchContext = useArtistSearchContext().context;
    const listArtists = useQuery(
        searchContext ? QueryKeys.Artist.Search(searchContext) : QueryKeys.Nothing(),
        () => searchContext ? SearchArtists(searchContext) : GetEmptyList<ArtistProfile>()
    )
    const nav = useNavigate()

    const handleClick = (a: ArtistProfile) => {
        nav(SkRoutes.Artist.Show(a.handle, searchContext))
    }

    if (!searchContext) return null;

    return (
        <div>
            <SkText variant='descriptor' style={{ margin: '0 10px', fontWeight: 'bold' }} faded>Keep Browsing</SkText>
            <div style={{ backgroundColor: Colors.MidDarkBackground, padding: 1, margin: UiConstants.tightSpacing, borderRadius: 20, maxWidth: UiConstants.feedItemWidth }}>
                <Flex row style={{ width: '100%', overflow: 'scroll', marginBottom: UiConstants.tightSpacing - 3, marginTop: UiConstants.tightSpacing + 5 }}>
                    <Bump w={ UiConstants.screenInset } />
                    {
                        listArtists?.data?.data?.map(a => (
                            <ArtistPlanet size={ 45 } artist={ a } onClick={ () => handleClick(a) } style={{ marginRight: 15, marginBottom: 10 }} />
                        ))
                    }
                    <Bump w={ 100 } />
                </Flex>
            </div>
        </div>
    )

})