import { FaCircleCheck } from "react-icons/fa6";
import React from "react"
import { NotificationMethod } from "../../constants/NotificationMethod"
import { UiConstants } from "../../constants/UiConstants"
import { Notifications } from "../../utils/Notifications"
import { Flex } from "../Flex"
import { SkButton } from "../SkButton"
import { SkText } from "../SkText"
import { NotificationMethodCard } from "./NotificationMethodCard"

export const NotificationSetupSuccessPage = React.memo((props: { method: NotificationMethod, onDone: () => void }) => {

    const { method, onDone } = props
    const meta = Notifications.useNotificationMethodStatus(method)

    return (
        <Flex style={{ flex: 1, padding: '0 20px' }} align='center'>

            <Flex style={{ flex: 1 }} justify='center' align='center'>
                <FaCircleCheck size={128} />
            </Flex>
            
            <SkText variant='c1'>You are good to go! &#127775;</SkText>
            <SkText variant='c1' faded style={{ marginBottom: 40, textAlign: 'center' }}>We'll { meta.flavorVerb } when one of your subscriptions opens for commissions.</SkText>

            <SkButton title='Heck Yeah!' onClick={ onDone } />

        </Flex>
    )

})