type ResolveHandler<TResult> = (result: TResult) => void
type RejectHandler = (error: Error) => void

export type PromiseBundle<TResult> = {
    promise: Promise<TResult>
    resolve: ResolveHandler<TResult>
    reject: RejectHandler
}

export const MakePromise = <TResult>(): PromiseBundle<TResult> => {

    let resolve: ResolveHandler<TResult> | null = null
    let reject: RejectHandler | null = null
    const promise = new Promise<TResult>((res, rej) => {
        resolve = res
        reject = rej
    })

    // Just in case, do a little busy waiting for the resolver. In reality this doesn't even loop once
    let attempts = 20
    while (!resolve) {
        console.log('...')
        if(--attempts <= 0) break;
    }

    return { promise, resolve: resolve as any, reject: reject as any }

}