import { BsBellFill, BsTelegram } from "react-icons/bs"
import { TelegramSetupPage } from "../components/Notifications/TelegramSetupPage"
import { WebPushSetupPage } from "../components/Notifications/WebPushSetupPage"
import { RemoveTelegramSubscriptionAction, RemoveWebPushSubscriptionAction } from "../data/actions/NotificationActions"
import { NotificationMethodProfile } from "../data/models/CurrentUser"
import { NotificationMethodStatus, Notifications } from "../utils/Notifications"

export type NotificationMethod = 'sms' | 'telegram' | 'webpush'

export type INotificationProvider = {

    name: string
    icon: (size: number, color: string) => JSX.Element
    flavorVerb: string
    notificationSubstateKey: string
    unconfiguredSublabel: string
    activeSublabel: string
    pausedSublabel: string
    getStatus: (profile: NotificationMethodProfile) => Promise<NotificationMethodStatus>
    editor?: (onDone: () => void) => JSX.Element
    details?: (profile: NotificationMethodProfile) => JSX.Element
    unsubscribe: () => Promise<void>

}
 
export const TelegramMetadataProvider: INotificationProvider = {
    name: 'Telegram',
    icon: (size, color) => <BsTelegram color={ color } />,
    flavorVerb: 'fling a Telegram message your way',
    notificationSubstateKey: 'telegram',
    unconfiguredSublabel: 'Set Up',
    activeSublabel: 'Enabled',
    pausedSublabel: 'Paused',
    getStatus: async (p) => p.status,
    editor: (onDone) => <TelegramSetupPage onDone={ onDone } />,
    unsubscribe: async () => {
        try { await RemoveTelegramSubscriptionAction() } catch (e) { console.log('Error while cleraing Telegram subscription', e) }
    }
}

export const WebPushMetadataProvider: INotificationProvider = {
    name: 'Browser',
    icon: (size, color) => <BsBellFill color={ color } />,
    flavorVerb: 'ping your device',
    notificationSubstateKey: 'webpush',
    unconfiguredSublabel: 'Set Up',
    activeSublabel: 'Enabled',
    pausedSublabel: 'Paused',
    getStatus: async (p) => {
        const sub = await Notifications.WebPush.getSubscription()
        if (!p?.details?.endpoint) return 'not-configured'
        return (p?.details?.endpoint === sub?.endpoint) ? 'active' : 'different-device'
    },
    editor: (onDone) => <WebPushSetupPage onDone={ onDone } />,
    unsubscribe: async () => {
        try { await Notifications.WebPush.unsubscribe() } catch (e) { console.log('Error while clearing client-side subscription', e) }
        try { await RemoveWebPushSubscriptionAction() } catch (e) { console.log('Error while clearing server-side subscription', e) }
    }
}