import React from "react"
import { BsFileArrowUp, BsFileBreak, BsFileCheck } from "react-icons/bs"
import { Colors } from "../../constants/Colors"
import { UiConstants } from "../../constants/UiConstants"
import { Bump } from "../Bump"
import { Flex } from "../Flex"
import { SkButton } from "../SkButton"
import { SkText } from "../SkText"

const ReportProgressPage = React.memo((props: {
    icon: any
    title: string
    subtitle?: string
    onDismiss?: () => void
}) => {

    const { title, subtitle, onDismiss, icon } = props

    return (
        <Flex align='center' style={{ padding: 20 }}>

            <Flex justify='center' align='center' style={{ flex: 1, margin: '80px 0' }}>
                { icon }
            </Flex>

            <SkText variant='section'>{ title }</SkText>
            <Bump h={ UiConstants.tightSpacing } />
            <SkText variant='c1' faded style={{ opacity: subtitle ? 1 : 0 }}>{ subtitle || '&nbsp;' }</SkText>

            <Bump h={ UiConstants.standardSpacing * 2 } />

            <SkButton onClick={ onDismiss } variant='secondary-invert' title={ onDismiss ? 'Dismiss' : 'Just a Sec' } style={{ opacity: onDismiss ? 1 : 0.4 }} />

        </Flex>
    )
})

export const SubmittingReportPage = React.memo(() => {
    return <ReportProgressPage icon={ <BsFileArrowUp size={ 80 } color='white' /> } title='Sending Report' />
})

export const ReportSubmittedPage = React.memo((props: { onDismiss: () => void }) => {
    return <ReportProgressPage icon={ <BsFileCheck size={ 80 } color={ Colors.SuccessGreen } /> } title='Report Sent' subtitle='Thank you for your report!' onDismiss={ props.onDismiss } />
})

export const ReportFailedPage = React.memo((props: { message: string, onDismiss: () => void }) => {
    return <ReportProgressPage icon={ <BsFileBreak size={ 80 } color={ Colors.BeautifulMagenta } /> } title='Cannot Send Report' subtitle={ props.message || 'We\'re sorry, we were unable to send your report' } onDismiss={ props.onDismiss } />
})