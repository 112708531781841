import React, { useEffect } from "react";
import { HtmlIds } from "../constants/HtmlIds";
import { UiConstants } from "../constants/UiConstants";
import { useAuth } from "../hooks/useAuth";
import { useWindowSize } from "../hooks/useWindowSize";
import { Flex } from "./Flex";

import "./ScreenWrapper.scss";

interface ScreenWrapperProps {
    className?: string;
    children: any;
    left?: any;
    contentWidth?: number;
    noHeader?: boolean;
    skipAuth?: boolean;
    padded?: boolean;
}

export const ScreenWrapper = React.memo((props: ScreenWrapperProps) => {

    const { left, children, padded = false, contentWidth, noHeader, skipAuth, className } = props;
    //const { isMobile, scale } = useWindowSize();
    //const hh = noHeader ? 0 : (isMobile ? UiConstants.headFootHeights.mobile.total : UiConstants.headFootHeights.desktop.total );  
    //const headerHeight = isMobile ? UiConstants.headFootHeights.mobile.header : UiConstants.headFootHeights.desktop.header;
    //const footerHeight = isMobile ? UiConstants.headFootHeights.mobile.footer : UiConstants.headFootHeights.desktop.footer;
    //const padding = noPadding ? 0 : UiConstants.screenInset * scale;
    //const maxHeightLeft = `calc(${window.innerHeight}px - ${headerHeight + footerHeight}px)`;
    const auth = useAuth();

    useEffect(() => {
        if (!skipAuth && (auth.state === 'needs-login')) auth.redirectToLogin()
    }, [ skipAuth, auth?.state ]);

    if (!skipAuth && auth.state !== 'ok') return null;

    const classes = [
        'screen-wrapper',
        left ? 'has-left' : '',
        className || '',
        padded ? 'padded' : ''
    ];

    return (
        <Flex className={classes.join(' ')} style={{
            // width: '100%',
            // paddingTop: `calc(env(safe-area-inset-top) + ${headerHeight}px)`,
            // paddingBottom: `calc(env(safe-area-inset-bottom) + ${footerHeight}px)`,
            // paddingLeft: left ? undefined : `${padding}px`,
            // paddingRight: left ? undefined : `${padding}px`,
            // boxSizing: 'border-box',
        }}>

            {left ? (
                <aside className="left scrollable" style={{
                    // position: 'sticky',
                    // boxSizing: 'border-box',
                    // top: `${headerHeight}px`,
                    // paddingLeft: padding, paddingRight: padding * 0.5,
                    // minHeight: `calc(${window.innerHeight}px - (${headerHeight + footerHeight}px) - (env(safe-area-inset-top) + env(safe-area-inset-bottom)))`, 
                    // maxHeight: '100px',
                    // overflow: 'scroll'
                }}>
                    {left}
                </aside>
            ) : null}
            <main 
                // row 
                // justify='center' 
                // align='stretch' 
                // htmlId={ HtmlIds.ScrollRoot } 
                // style={{ flex: 1, overflow: 'scroll' }}
            >
                {children}

                {/* <div style={{ maxWidth: contentWidth || 1400, width: '100%' }}>
                    
                </div> */}
            </main>

        </Flex>
    );


})