import { useQuery } from "@tanstack/react-query"
import React from "react"
import { Col, Container, Row } from "react-grid-system"
import { ArtistList } from "../../components/ArtistList"
import { Bump } from "../../components/Bump"
import { SkCard } from "../../components/Card"
import { Flex } from "../../components/Flex"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { ShortArtistCell } from "../../components/ShortArtistCell"
import { SkText } from "../../components/SkText"
import { UserImage } from "../../components/UserImage"
import { Colors } from "../../constants/Colors"
import { UiConstants } from "../../constants/UiConstants"
import { GetAppStatsAction } from "../../data/actions/AdminActions"
import { QueryKeys } from "../../data/QueryKeys"
import { useWindowSize } from "../../hooks/useWindowSize"
import { FormatDate } from "../../utils/FormatDate"

export const AppStatsScreen = React.memo(() => {

    const stats = useQuery(QueryKeys.Admin.AppStats(), GetAppStatsAction)
    const { isMobile } = useWindowSize()

    return (
        <ScreenWrapper>

            <Container fluid>
                <Row>
                    <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }><FigureCard title='Total Users' number={ stats.data?.data?.totalUsers! } /></Col>
                    <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }><FigureCard title='Total Artists' number={ stats.data?.data?.totalArtists! } /></Col>
                    <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }><FigureCard title='Total Posts' number={ stats.data?.data?.totalPosts! } /></Col>
                    <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }><FigureCard title='Total Tags' number={ stats.data?.data?.totalTags! } /></Col>
                </Row>
                <Row>
                    <Bump h={ 30 } />
                    <Col xs={ 12 }><SkText variant='section'>Recent Users</SkText></Col>
                    <Bump h={ 10 } />
                </Row>
                <Row>
                    { stats.data?.data?.recentUsers.map(u => (
                        <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }>
                            <SkCard style={{ marginBottom: UiConstants.tightSpacing }}>
                                <Flex row>
                                    <UserImage url={ u.imageUrl || '' } userId={ u.id } size={ isMobile ? 40 : 50 } />
                                    <Flex style={{ marginLeft: 20 }}>
                                        <SkText variant='name'>{ u.name }</SkText>
                                        <SkText variant='c2' faded>Joined { FormatDate.adaptiveTime(u.created) }</SkText>
                                    </Flex>
                                </Flex>
                            </SkCard>
                        </Col>
                    )) }
                </Row>
                <Row>
                    <Bump h={ 30 } />
                    <Col xs={ 12 }><SkText variant='section'>Recent Artists</SkText></Col>
                    <Bump h={ 10 } />
                </Row>
                <Row>
                    { stats.data?.data?.recentArtists.map(a => (
                        <Col lg={ 3 } md={ 6 } sm={ 12 } xs={ 12 }><ShortArtistCell artist={ a } /></Col>
                    )) }
                </Row>
            </Container>


        </ScreenWrapper>
    )

})

const FigureCard = React.memo((props: { title: string, number: number }) => {

    const { title, number } = props

    return (
        <SkCard style={{ background: Colors.Gradient, marginBottom: UiConstants.standardSpacing }}>
            <Flex>
                <SkText variant='c2'>{ title }</SkText>
                <SkText variant='headline'>{ number }</SkText>
            </Flex>
        </SkCard>
    )

})