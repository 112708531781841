import React from "react"
import { ArtistProfile } from "../../data/models/Artist"
import { ArtistOnListButton } from "./ArtistOnListButton"

export const ArtistFollowButton = React.memo((props: { artistHandle: string, style?: React.CSSProperties }) => {

    const { artistHandle, style } = props
    return (<ArtistOnListButton artistHandle={ artistHandle } list='followed' labels={{ loading: '...', on: 'Following', off: 'Follow' }} style={ style } />)

})
